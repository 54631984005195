import React, { useState, useMemo } from "react";
import { toast } from "react-toastify";
import { useQueryClient } from "@tanstack/react-query";

import noop from "lodash/noop";
import some from "lodash/some";
import head from "lodash/head";
import isEmpty from "lodash/isEmpty";

import { Dropdown, Button, Popconfirm, Checkbox } from "antd";
import {
    ContactsOutlined,
    DeleteOutlined,
    DownOutlined,
    DownloadOutlined,
    InteractionOutlined,
    MessageOutlined,
    ShareAltOutlined,
    StopOutlined
} from "@ant-design/icons";

import { CustomModal } from "../../modal";
import ContactMessageModalContent from "../../../views/AllContacts/ContactMessageModalContent";
import {
    useBulkUpdateContactStatus,
    useDeleteContact,
    useGetSegments,
    useSendContactCard,
    useSendOnBoardForm
} from "../../../hooks/api/contacts";
import AddContactModal from "../../../views/AllContacts/AddContactModal";
import ImportContactModal from "../../../views/AllContacts/ImportContactModal";
import { PRESET_SEGMENTS_KEYS } from "../ContactSegments";

import { OnBoardIcon, SendContactCardIcon } from "../../../assets/svg-icon";
import { ContactStatus } from "../../../constant";
import { connect } from "react-redux";
import ImportDeleteContactModal from "../../../views/AllContacts/ImportDeleteContactModal";

const ContactsActions = ({
    selectedContacts = [],
    selectedSegments = [],
    fetchParams = {},
    onAction = noop,
    isAdmin = false,
    onSuccess = noop
}) => {
    const queryClient = useQueryClient();
    const [isMessageFormVisible, showMessageForm] = useState(false);
    const [isNewContactFormVisible, showNewContactForm] = useState(false);
    const [isImportFormVisible, showImportForm] = useState(false);
    const [isBulkDeleteFormVisible, showBulkDeleteForm] = useState(false);
    const [onBoardMessage, sendOnboardMessage] = useState(true);

    const segments = useGetSegments();

    const sendOnBoardForm = useSendOnBoardForm({
        onSuccess() {
            toast("Onboard Form(s) Sent!", { containerId: "success" }) && onAction();
        },
        onError() {
            toast("Something went wrong", { containerId: "error" });
        }
    });
    const sendContactCard = useSendContactCard({
        onSuccess() {
            toast("Contact Card(s) Sent!", { containerId: "success" }) && onAction();
        },
        onError() {
            toast("Something went wrong", { containerId: "error" });
        }
    });
    const bulkUpdateContactStatus = useBulkUpdateContactStatus();
    const deleteContact = useDeleteContact();

    const ids = useMemo(() => selectedContacts.map(({ Id }) => Id), [selectedContacts]);
    const isAllContactsHaveNumber = useMemo(() => !some(selectedContacts, ["PhoneNumber", null]), [selectedContacts]);

    const items = useMemo(
        () => [
            // ALL SELECTED CONTACTS HAVE PHONE AND ACTIVE SEGMENTS
            ...(![PRESET_SEGMENTS_KEYS.INVITED, PRESET_SEGMENTS_KEYS.UNSUBSCRIBED].includes(
                head(selectedSegments)
            ) && isAllContactsHaveNumber
                ? [
                      {
                          key: "new-message",
                          label: (
                              <>
                                  <MessageOutlined /> New Message
                              </>
                          )
                      }
                  ]
                : []),
            {
                key: "new-contact",
                label: (
                    <>
                        <ContactsOutlined /> New Contact
                    </>
                )
            },
            {
                key: "import",
                label: (
                    <>
                        <DownloadOutlined /> Import Contacts
                    </>
                )
            },
            // ALL SELECTED CONTACTS (ONE OR MORE) HAVE PHONE AND ACTIVE SEGMENTS
            ...(!isEmpty(ids) &&
            ![PRESET_SEGMENTS_KEYS.INVITED, PRESET_SEGMENTS_KEYS.UNSUBSCRIBED].includes(
                head(selectedSegments)
            ) && isAllContactsHaveNumber
                ? [
                      {
                          key: "onboard-form",
                          label: (
                              <Popconfirm
                                  title="Send Onboard Form"
                                  description={() => (
                                      <>
                                          <div className="mb-2">
                                              User(s) will recieve a message with the Onboardform.
                                              <br />
                                              Responses will be reflected in their contact card
                                          </div>
                                          <br />
                                          <Checkbox
                                              checked={!onBoardMessage}
                                              onChange={() => sendOnboardMessage(!onBoardMessage)}
                                              onClick={(ev) => ev.stopPropagation()}
                                          >
                                              Send onboard form only with no onboard message
                                          </Checkbox>
                                      </>
                                  )}
                                  showCancel={false}
                                  zIndex={2000}
                                  okType="default"
                                  okText="Send Now"
                                  placement="right"
                                  trigger="hover"
                                  onConfirm={() =>
                                      sendOnBoardForm({
                                          Ids: ids,
                                          SentOnboardForm: !onBoardMessage ? 0 : 1
                                      })
                                  }
                              >
                                  <img
                                      src={OnBoardIcon}
                                      className="img-fluid w-5 ml-[-4px]"
                                      alt=""
                                  />{" "}
                                  Send Onboard Form
                              </Popconfirm>
                          )
                      },
                      {
                          key: "contact-card",
                          label: (
                              <Popconfirm
                                  title="Resend Contact Card"
                                  description="The subscriber will receive a message with your contact card. Are you sure you want to continue?"
                                  showCancel={false}
                                  zIndex={2000}
                                  okType="default"
                                  okText="Resend Now"
                                  placement="right"
                                  trigger="hover"
                                  onConfirm={() => sendContactCard({ Ids: ids })}
                              >
                                  <img
                                      src={SendContactCardIcon}
                                      className="img-fluid w-5 ml-[-4px]"
                                      alt=""
                                  />{" "}
                                  Send Contact Card
                              </Popconfirm>
                          )
                      }
                  ]
                : []),
                // SELECTED CONTACTS AND ACTIVE SEGMENTS
                ...(!isEmpty(ids) &&
                ![PRESET_SEGMENTS_KEYS.INVITED, PRESET_SEGMENTS_KEYS.UNSUBSCRIBED].includes(
                    head(selectedSegments)
                )
                    ? [
                          {
                              key: "unsubscribe",
                              label: (
                                  <Popconfirm
                                      title={`Move ${ids.length} contact(s) to unbsubscribed.`}
                                      showCancel={false}
                                      zIndex={2000}
                                      okType="default"
                                      okText="Unsubscribe"
                                      placement="right"
                                      trigger="hover"
                                      onConfirm={() =>
                                          bulkUpdateContactStatus({
                                              Ids: ids,
                                              OptStatus: ContactStatus.OptOut
                                          }) && onAction()
                                      }
                                  >
                                      <StopOutlined /> Move to Unsubscribed
                                  </Popconfirm>
                              )
                          }
                      ]
                    : []),
            // SELECTED CONTACTS AND INVITED SEGMENT
            ...(!isEmpty(ids) && selectedSegments.includes(PRESET_SEGMENTS_KEYS.INVITED)
                ? [
                      {
                          key: "invite",
                          label: (
                              <Popconfirm
                                  title="Invite Again"
                                  description="Send invitation to contact(s) once again?"
                                  showCancel={false}
                                  zIndex={2000}
                                  okType="default"
                                  okText="Invite"
                                  placement="right"
                                  trigger="hover"
                                  onConfirm={() =>
                                      bulkUpdateContactStatus({
                                          Ids: ids,
                                          OptStatus: ContactStatus.OptInvited
                                      }) && onAction()
                                  }
                              >
                                  <ShareAltOutlined /> Invite Again
                              </Popconfirm>
                          )
                      },
                      {
                          key: "activate",
                          label: (
                              <Popconfirm
                                  title="Move to Active"
                                  description="Moving contacts to active will opt the user(s) in without their consent. Do you wish to continue?"
                                  showCancel={false}
                                  zIndex={2000}
                                  okType="default"
                                  okText="Move"
                                  placement="right"
                                  trigger="hover"
                                  onConfirm={() =>
                                      bulkUpdateContactStatus({
                                          Ids: ids,
                                          OptStatus: ContactStatus.OptIn
                                      }) && onAction()
                                  }
                              >
                                  <InteractionOutlined /> Move to Active
                              </Popconfirm>
                          )
                      }
                  ]
                : []),
            // SELECTED CONTACTS AND UNSUBSCRIBED SEGMENT
            ...(!isEmpty(ids) && selectedSegments.includes(PRESET_SEGMENTS_KEYS.UNSUBSCRIBED)
                ? [
                      {
                          key: "activate",
                          label: (
                              <Popconfirm
                                  title="Move to Active"
                                  description="Moving contacts to active will opt the user(s) in without their consent. Do you wish to continue?"
                                  showCancel={false}
                                  zIndex={2000}
                                  okType="default"
                                  okText="Move"
                                  placement="right"
                                  trigger="hover"
                                  onConfirm={() =>
                                      bulkUpdateContactStatus({
                                          Ids: ids,
                                          OptStatus: ContactStatus.OptIn
                                      }) && onAction()
                                  }
                              >
                                  <InteractionOutlined /> Move to Active
                              </Popconfirm>
                          )
                      }
                  ]
                : []),
            // SINGLE SELECTED CONTACT
            ...(ids?.length === 1
                ? [
                      {
                          key: "delete-contact",
                          label: (
                              <Popconfirm
                                  title="Delete Contact"
                                  description="Are you sure you want to delete that contact? This action is irreversible."
                                  showCancel={false}
                                  zIndex={2000}
                                  okType="default"
                                  okText="Delete Contact"
                                  placement="right"
                                  trigger="hover"
                                  onConfirm={() => deleteContact(head(ids)) && onAction()}
                              >
                                  <DeleteOutlined /> Delete Contact
                              </Popconfirm>
                          )
                      }
                  ]
                : []),
            // ADMIN ONLY
            ...(isAdmin
                ? [
                      {
                          key: "bulk-delete",
                          label: (
                              <>
                                  <DeleteOutlined /> Imports Contacts to Delete
                              </>
                          )
                      }
                  ]
                : [])
        ],
        [
            selectedSegments,
            onBoardMessage,
            sendContactCard,
            sendOnBoardForm,
            bulkUpdateContactStatus,
            deleteContact,
            onAction,
            isAdmin,
            isAllContactsHaveNumber,
            ids
        ]
    );

    const onClick = ({ key }) => {
        switch (key) {
            case "new-message":
                showMessageForm(true);
                break;
            case "new-contact":
                showNewContactForm(true);
                break;
            case "import":
                showImportForm(true);
                break;
            case "bulk-delete":
                showBulkDeleteForm(true);
                break;
            default:
                break;
        }
    };

    return (
        <>
       
            <Dropdown menu={{ items, onClick }}>
                <Button className="bg-main text-white h-12 text-md font-medium" shape="round">
                    Actions
                    <DownOutlined />
                </Button>
            </Dropdown>

            {isMessageFormVisible && (
                <CustomModal isOpen={isMessageFormVisible}>
                    <ContactMessageModalContent
                        checkedContacts={selectedContacts}
                        handleSendMessageModel={() => showMessageForm(false)}
                        showSendMessageAlert={() => showMessageForm(false) && onAction()}
                        allContactSelected={false}
                        getFilterSegmentRequestPayloads={() => fetchParams}
                        segments={segments}
                    />
                </CustomModal>
            )}
            {isNewContactFormVisible && (
                <CustomModal
                    isOpen={isNewContactFormVisible}
                    handleModalState={() => showNewContactForm(false)}
                >
                    <AddContactModal
                        handleAddContactCallBack={() =>
                            showNewContactForm(false) &&
                            queryClient.invalidateQueries(["contacts", fetchParams]) &&
                            queryClient.invalidateQueries(["contactsTotalCount"]) &&
                            onAction
                        }
                        handleClose={() => showNewContactForm(false)}
                    />
                </CustomModal>
            )}
            {isImportFormVisible && (
                <CustomModal isOpen={isImportFormVisible}>
                    <ImportContactModal
                        handleImportContactModalClose={() => showImportForm(false)}
                        handleImportContactAlertOpen={() => showImportForm(false)}
                        onSuccess={() => {
                            showImportForm(false);
                            onAction();
                        }}
                    />
                </CustomModal>
            )}
            {isBulkDeleteFormVisible && (
                <CustomModal isOpen={isBulkDeleteFormVisible}>
                    <ImportDeleteContactModal
                        handleImportDeleteContactModalOpen={() =>
                            showBulkDeleteForm(false) &&
                            queryClient.invalidateQueries(["contacts", fetchParams]) &&
                            queryClient.invalidateQueries(["contactsTotalCount"]) &&
                            onAction
                        }
                        onSuccess={() => {
                            onSuccess()
                        }}
                    />
                </CustomModal>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        isAdmin: state.auth.isAdmin
    };
};

export default connect(mapStateToProps)(ContactsActions);