import React from "react";
import "../../App.css";
import { MessageBox } from "../../components/MessageBox/index";
import { TextSmsChip } from "../../components/text";
import { sendMessage } from "../../redux/actions";
import { connect } from "react-redux";
import { imageUpload, getContactsList, getFilteredContactsList } from "../../redux/actions";
import { Spinner } from "../../components/Loader";
import { ContactStatus } from "../../config/constants";
import { TagInputComponent } from "../../components/text";
class ContactMessageModalContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedContacts: {},
            selectedSegments: [],
            selectAllContacts: false,
            content: "",
            mediaObject: null,
            loading: false,
            contactError: false,
            contentError: false,
            allContactSelected: false
        };
        this.abortController = new AbortController();
    }
    componentDidMount() {
        if (this.props.allContactSelected) {
            this.setState({
                allContactSelected: true
            });
        } else {
            if (!this.props.allContactSelected && this.props.checkedContacts) {
                this.updateSelectedContact(this.props.checkedContacts);
            }
        }
    }
    updateSelectedContact = (contacts) => {
        this.setState({ contactError: false });
        const selectedContacts = {};
        if (!contacts.length) {
            this.setState({
                selectedContacts
            });
            return;
        }
        contacts.map((contact) => (selectedContacts[contact.Id] = contact));
        this.setState({
            selectedContacts,
            contactError: false
        });
    };
    handleChange = (e) => {
        e.preventDefault();
        if (e.target.value !== "") {
            this.setState({ contentError: false });
        }
        this.setState({
            content: e.target.value
        });
    };
    updateTextState = (text) => {
        if (text !== "") {
            this.setState({ contentError: false });
        }
        this.setState({
            content: text
        });
    };
    clearFileInput = () => {
        this.setState({
            mediaObject: null
        });
    };
    updateMediaObjectGiyf = (Obj) => {
        this.setState({ mediaObject: Obj });
    };
    _handleImageChange = (Obj) => {
        this.setState({ mediaObject: Obj });
    };
    sendMessage = () => {
        const {
            content,
            selectedContacts,
            selectAllContacts,
            selectedSegments,
            mediaObject,
            allContactSelected
        } = this.state;
        let ContactIds = [];
        let SegmentIds = [];
        if (mediaObject === null && content === "") {
            this.setState({ contentError: true });
            if (
                !Object.keys(selectedContacts).length &&
                selectedSegments.length === 0 &&
                !selectAllContacts &&
                !allContactSelected
            ) {
                this.setState({ contactError: true });
                return;
            } else {
                this.setState({ contactError: false });
            }
            return;
        } else {
            this.setState({ contentError: false });
        }
        if (
            !Object.keys(selectedContacts).length &&
            selectedSegments.length === 0 &&
            !selectAllContacts &&
            !allContactSelected
        ) {
            this.setState({ contactError: true });
            return;
        } else {
            this.setState({ contactError: false });
        }
        if (!selectAllContacts) {
            for (const key in selectedContacts) {
                ContactIds.push(selectedContacts[key].Id);
            }
            for (const key in selectedSegments) {
                SegmentIds.push(selectedSegments[key].Id);
            }
        }

        let mediaObj = null;
        this.setState({ loading: true });
        this.uploadMediaObj(mediaObject)
            .then((res) => {
                if (res) {
                    mediaObj = res;
                }
                let RequestPayload = {};
                if (this.props.getFilterSegmentRequestPayloads) {
                    RequestPayload = this.props.getFilterSegmentRequestPayloads();
                }
                RequestPayload["Content"] = content;
                RequestPayload["AllSelected"] = selectAllContacts || allContactSelected ? 1 : 0;
                RequestPayload["ContactIds"] = ContactIds;
                RequestPayload["SelectAllContacts"] =
                    selectAllContacts || allContactSelected ? 1 : 0;
                RequestPayload["SegmentIds"] = SegmentIds ? SegmentIds : [];
                RequestPayload["MediaUrl"] = mediaObj ? mediaObj : null;
                this.props
                    .sendMessage(RequestPayload)
                    .then((res) => {
                        if (res) {
                            this.props.handleSendMessageModel();
                            this.props.showSendMessageAlert();
                        }
                    })
                    .catch((e) => {
                        this.setState({ loading: false });
                    });
            })
            .catch((e) => {
                this.setState({ loading: false });
            });
    };
    handleContactCheckboxChange = (e) => {
        this.setState({ selectAllContacts: e.target.checked });
        if (e.target.checked) {
            this.setState({ selectedContacts: {}, campaignId: null, selectedSegments: {} });
            this.props.getContactsList({ OptStatus: ContactStatus.OPT_IN });
        }
    };
    handleContactRemove = (contact) => {
        const { selectedContacts } = this.state;

        delete selectedContacts[contact.Id];
        this.setState({ selectedContacts });
    };
    uploadMediaObj = async (Obj) => {
        return new Promise((resolve, reject) => {
            if (Obj === null) {
                resolve(false);
            } else if (Obj.type === "image") {
                let formData = new FormData();
                formData.append("file", Obj.Obj);
                this.props.imageUpload(formData).then(
                    (res) => {
                        resolve(res.location);
                    },
                    (err) => {
                        this.setState({ loading: false });
                        reject(err);
                    }
                );
            } else {
                resolve(Obj.Obj.url);
            }
        });
    };
    handleSegmentSelect = (isChecked, segment) => {
        this.setState({ contactError: false });
        const selectedSegments = { ...this.state.selectedSegments };

        if (isChecked) {
            selectedSegments[segment.Id] = segment;
            this.deleteSegmentContacts(segment);
            this.addSegmentContactsCount(segment);
        } else {
            delete selectedSegments[segment.Id];
        }
        this.setState({ selectedSegments });
    };
    deleteSegmentContacts = (segment) => {
        let selectedContacts = this.getDeletedSegmentContacts(segment);
        this.setState({ selectedContacts });
    };
    addSegmentContactsCount = async (segment) => {
        let segmentContacts = { ...this.state.segmentContacts };

        if (!segmentContacts[segment.Id]) {
            try {
                let contacts = await this.props.getContactsList({
                    OptStatus: ContactStatus.OPT_IN,
                    SegmentIds: segment.Id
                });
                segmentContacts[segment.Id] = contacts.TotalContacts;
                this.setState({ segmentContacts });
            } catch (err) {}
        }
    };

    getDeletedSegmentContacts = (segment) => {
        const selectedContacts = { ...this.state.selectedContacts };

        Object.keys(selectedContacts).forEach((key) => {
            const contact = selectedContacts[key];
            const contactSegmentIds = contact.Segments.map((val) => val.Id);
            let deleteContact = false;
            if (Array.isArray(segment)) {
                segment.forEach((id) => {
                    if (contactSegmentIds.includes(id)) {
                        deleteContact = true;
                    }
                });
            } else if (contactSegmentIds.includes(segment.Id)) {
                deleteContact = true;
            }
            if (deleteContact) {
                delete selectedContacts[key];
            }
        });

        return selectedContacts;
    };
    updateSelectedSegment = (sagments) => {
        const selectedSegments = {};
        if (!sagments.length) {
            this.setState({
                selectedSegments
            });
            return;
        }
        sagments.map((sagment) => (selectedSegments[sagment.Id] = sagment));
        this.setState({
            selectedSegments,
            contactError: false
        });
    };
    checkValidation = () => {
        const { selectedContacts, selectedSegments, mediaObject, content } = this.state;
        if (mediaObject === null && content === "") {
            return false;
        } else if (!Object.keys(selectedContacts).length && selectedSegments.length === 0) {
            return false;
        } else {
            return true;
        }
    };
    deleteSelectedContacts = () => {
        this.setState({
            allContactSelected: false
        });
    };
    render() {
        const {
            selectedContacts,
            selectedSegments,
            selectAllContacts,
            loading,
            contactError,
            contentError
        } = this.state;
        return (
            <div className="contacts-newmessage-modal">
                <div className="modal-content newmessage-modals">
                    <div className="modal-header">
                        <span
                            className="close"
                            onClick={this.props.handleSendMessageModel}
                            id="newmessage-closebtn"
                        >
                            &times;
                        </span>
                        <h3>New Message</h3>
                    </div>

                    <div className="setting-tab-content">
                        <div className="newmessage-modal-form">
                            {/* <form> */}
                            <label>To:</label>
                            <div className={`sms-campaign-contact-search-container`}>
                                {/* <TextSmsCampaignContactSearch
                                    onClick={this.handleContactSelect}
                                    ref={this.searchComponent}
                                    selectedContacts={selectedContacts}
                                    selectedSegments={selectedSegments}
                                /> */}

                                <TagInputComponent
                                    segments={this.props.segments}
                                    updateContact={this.updateSelectedContact}
                                    updateSagments={this.updateSelectedSegment}
                                    selectedSegments={selectedSegments}
                                    selectedContacts={selectedContacts}
                                    allContactSelected={this.state.allContactSelected}
                                    deleteSelectedContacts={this.deleteSelectedContacts}
                                    hideAllContacts={true}
                                    // resetTagInput={false}
                                />
                                {contactError && (
                                    <p className="text-sms-campaign-validation-error">
                                        Please select contact or segment. It is required field
                                    </p>
                                )}
                            </div>
                            <div className="step-one-right-content fancy-scroll">
                                <div className="selected-contacts-wrapper">
                                    {selectAllContacts && (
                                        <TextSmsChip
                                            title={`Contacts from Campaign`}
                                            classes="campaign"
                                            onCancelClick={() => {
                                                this.setState({
                                                    selectAllContacts: false
                                                });
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                            <label>Message body:</label>
                            <MessageBox
                                textAreaRows={10}
                                handleChange={this.handleChange}
                                clearFileInput={this.clearFileInput}
                                _handleImageChange={this._handleImageChange}
                                updateMediaObjectGiyf={this.updateMediaObjectGiyf}
                                updateTextState={this.updateTextState}
                            />
                            {contentError && (
                                <p className="text-sms-campaign-validation-error">
                                    Please add content or media URL.It is required field
                                </p>
                            )}
                            <div className="form-btn">
                                {!loading ? (
                                    <button
                                        onClick={this.sendMessage}
                                        className={`chat-form-btn ${
                                            this.checkValidation() ? `valid` : ``
                                        }`}
                                    >
                                        Send
                                    </button>
                                ) : (
                                    <button disabled={true} className="chat-form-btn">
                                        <Spinner size="lg" color={"white"} />
                                    </button>
                                )}
                            </div>
                            {/* </form> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        sendMessage: (requestPayload) => dispatch(sendMessage(requestPayload)),
        imageUpload: (bodyData) => dispatch(imageUpload(bodyData)),
        getContactsList: (paramsObj) => dispatch(getContactsList(paramsObj)),
        getFilteredContactsList: (paramsObj) => dispatch(getFilteredContactsList(paramsObj))
    };
};

export default connect(null, mapDispatchToProps)(ContactMessageModalContent);
