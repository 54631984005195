import DefaultSideBar from "./containers/DefaultLayout/DefaultSideBar";
import WebChat from "./views/WebChat/WebChat";
import InboxAllMessages from "./views/InboxAllMessages/InboxAllMessages";
import InboxOptOutMessages from "./views/InboxOptOutMessages/InboxOptOutMessages";
import Contacts from "./views/Contacts";
import ImportContacts from "./views/ImportContacts/ImportContacts";
import TextSmsCampaigns from "./views/TextSmsCampaign/TextSmsCampaigns";
import Campaigns from "./views/Campaigns/Campaigns";
import { ApplicationTypes } from "./config/constants";
import EmailCampaign from "./views/EmailCampaign";

const routes = [
    {
        path: "/dashboard/inbox",
        name: "Texts",
        component: DefaultSideBar,
        permission: [ApplicationTypes.CLUB_CHAT],
        routes: [
            {
                path: "/dashboard/inbox/all-messages",
                name: "All Messages",
                component: InboxAllMessages
            },
            {
                path: "/dashboard/inbox/opt-out-messages",
                name: "Opt-Out Messages",
                component: InboxOptOutMessages
            }
        ]
    },
    {
        path: "/dashboard/contacts",
        name: "Contacts",
        component: DefaultSideBar,
        permission: [ApplicationTypes.CLUB_CHAT],
        routes: [
            {
                path: "/dashboard/contacts/all-contacts",
                name: "All Contacts",
                component: Contacts
            },
            {
                path: "/dashboard/contacts/import-contacts",
                name: "Import Contacts",
                component: ImportContacts
            }
        ]
    },
    {
        path: "/dashboard/text",
        name: "Text",
        component: DefaultSideBar,
        permission: [ApplicationTypes.CLUB_CHAT],
        routes: [
            {
                path: "/dashboard/text/sms-campaign",
                name: "SMS Campaign",
                component: TextSmsCampaigns
            },
            {
                path: "/dashboard/text/edit-campaign/:id",
                name: "Edit Campaign",
                component: TextSmsCampaigns
            }
        ]
    },
    {
        path: "/dashboard/email",
        name: "Email",
        component: DefaultSideBar,
        permission: [ApplicationTypes.CLUB_CHAT],
        routes: [
            {
                path: "/dashboard/email/campaign",
                name: "Email Campaign",
                component: EmailCampaign
            },
            {
                path: "/dashboard/email/edit-campaign/:id",
                name: "Email Campaign",
                component: EmailCampaign
            },
        ]
    },
    {
        path: "/dashboard/reports",
        name: "Reports",
        component: DefaultSideBar,
        permission: [ApplicationTypes.CLUB_CHAT],
        routes: [
            {
                path: "/dashboard/reports/campaigns",
                name: "Campaigns",
                component: Campaigns
            }
        ]
    },
    {
        path: "/dashboard/webchat",
        name: "WebChat",
        permission: [ApplicationTypes.WEB_CHAT],
        component: WebChat
    }
];

export default routes;
