import { errorToaster } from "../../utils/loggerUtils";
import { fetchUtil } from "../../utils/fetchUtils";
import { appendQueryParams } from "../../utils/urlUtils";
import { PAGINATION_LIMIT } from "../../config/constants";
import { scrollToBottom } from "../../utils/scrollUtils";
import { getToken } from "../../utils/tokenUtils";
export const REMOVE_CURRENT_CHAT = "REMOVE_CURRENT_CHAT";
export const SET_UNREAD_CHAT_AND_LIST = "SET_UNREAD_CHAT_AND_LIST";
export const SET_UNREAD_WEBCHAT_LIST = "SET_UNREAD_WEBCHAT_LIST";
export const SET_IMAGE_LOADING_STATUS = "SET_IMAGE_LOADING_STATUS";
export const SET_IMAGE_LOADING_SCROLL = "SET_IMAGE_LOADING_SCROLL";
export const CHAT_LIST_REQUEST = "CHAT_LIST_REQUEST";
export const CHAT_LIST_SUCCESS = "CHAT_LIST_SUCCESS";
export const CHAT_LIST_FAIL = "CHAT_LIST_FAIL";
export const SET_CURRENT_CHAT_DETAILS = "SET_CURRENT_CHAT_DETAILS";
export const SET_CHAT_DETAIL_FROM_ALL_CONTACTS = "SET_CHAT_DETAIL_FROM_ALL_CONTACTS";
export const CHAT_MESSAGES_REQUEST = "CHAT_MESSAGES_REQUEST";
export const CHAT_MESSAGES_SUCCESS = "CHAT_MESSAGES_SUCCESS";
export const CHAT_MESSAGES_FAIL = "CHAT_MESSAGES_FAIL";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const CHANGE_MESSAGE_ACKNOWLEDGEMENT = "CHANGE_MESSAGE_ACKNOWLEDGEMENT";
export const DELETE_MESSAGE = "DELETE_MESSAGE";
export const SET_MESSAGE_ERROR = "SET_MESSAGE_ERROR";
export const MOVE_CHAT_TO_TOP = "MOVE_CHAT_TO_TOP";
export const READ_MESSAGE_SUCCESS = "READ_MESSAGE_SUCCESS";
export const CHANGE_CURRENT_CHAT_CONTACT_STATUS = "CHANGE_CURRENT_CHAT_CONTACT_STATUS";
export const CHANGE_CHAT_CONTACT_STATUS = "CHANGE_CHAT_CONTACT_STATUS";
export const UPDATE_CHAT_LIST_OBJECT = "UPDATE_CHAT_LIST_OBJECT";
export const UPDATE_CHAT_LIST_ON_STATUS_CHANGE = "UPDATE_CHAT_LIST_ON_STATUS_CHANGE";
export const UPDATE_CHAT_READ_STATUS = "UPDATE_CHAT_READ_STATUS";
export const CHAT_NOTIFICATION_STATUS = "CHAT_NOTIFICATION_STATUS";
export const getChatList = (paramsObj = {}, signal = null) => (dispatch, getState) => {
    dispatch({
        type: CHAT_LIST_REQUEST,
        Page: paramsObj.Page
    });
    const token = getToken();
    return fetchUtil({
        url: appendQueryParams("/chat", {
            Limit: PAGINATION_LIMIT,
            Column: "ReadStatus",
            ...paramsObj
        }),
        token,
        ...(signal && {
            signal
        })
    })
        .then((res) => {
            dispatch({
                type: CHAT_LIST_SUCCESS,
                payload: res,
                Page: paramsObj.Page
            });
            return Promise.resolve(res.Chats);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return Promise.reject(false);
            }
            dispatch({
                type: CHAT_LIST_FAIL
            });
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getUnreadChatCountAndList = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/chat/count/unread`,
        token
    })
        .then((res) => {
            dispatch(setUnreadChatAndList(res));
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const getWebchatUnreadCount = () => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/User/Webchat/getUnReadChats`,
        token
    })
        .then((res) => {
            dispatch(setUnreadWebChatList(res.data.Messages));
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const updateChatListObject = (data) => (dispatch, getState) => {
    dispatch({
        type: UPDATE_CHAT_LIST_OBJECT,
        payload: data
    });
};
export const updateChatListOnStatusChange = (chatId) => (dispatch) => {
    dispatch({
        type: UPDATE_CHAT_LIST_ON_STATUS_CHANGE,
        payload: { chatId }
    });
};
export const sendMessage = (requestPayload) => (dispatch, getState) => {
    const token = getToken();
    const body = JSON.stringify(requestPayload);
    return fetchUtil({
        url: `/chat/send/message`,
        token,
        body,
        method: "POST"
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getGiyf = (query, limit, offset) => (dispatch, getState) => {
    const token = getToken();
    let body = JSON.stringify({
        q: query,
        limit: limit,
        offset: offset
    });
    return fetchUtil({
        url: `/gify/search`,
        method: "POST",
        token,
        body
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getSuggestionGiyf = (query) => (dispatch, getState) => {
    const token = getToken();
    let body = JSON.stringify({
        q: query
    });
    return fetchUtil({
        url: `/gify/search/tag`,
        method: "POST",
        token,
        body
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

const getChatById = (id) => (dispatch, getState) => {
    const token = getToken();

    return fetchUtil({
        url: `/chat/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const removeCurrentChat = () => (dispatch, getState) => {
    dispatch({ type: REMOVE_CURRENT_CHAT });
};
export const getCurrentChat = (id) => (dispatch, getState) => {
    dispatch(getChatById(id))
        .then((res) => {
            dispatch(setCurrentChatDetails(res));
        })
        .catch((err) => {
            errorToaster(err.Message);
        });
};
export const setCurrentChatDetails = (data) => {
    return {
        type: SET_CURRENT_CHAT_DETAILS,
        payload: data
    };
};
export const setUnreadChatAndList = (data) => {
    return {
        type: SET_UNREAD_CHAT_AND_LIST,
        payload: data
    };
};

export const setUnreadWebChatList = (data) => {
    return {
        type: SET_UNREAD_WEBCHAT_LIST,
        payload: data
    };
};

export const setImageLoadingStatus = (data) => {
    return {
        type: SET_IMAGE_LOADING_STATUS,
        payload: data
    };
};
export const setImageLoadingScroll = (data) => {
    return {
        type: SET_IMAGE_LOADING_SCROLL,
        payload: data
    };
};
export const setChatDetailsFromAllContacts = (data) => {
    return {
        type: SET_CHAT_DETAIL_FROM_ALL_CONTACTS,
        payload: data
    };
};
export const getChatMessageList = (id = 377, paramsObj = {}, signal = null) => (
    dispatch,
    getState
) => {
    dispatch({
        type: CHAT_MESSAGES_REQUEST,
        Page: paramsObj.Page
    });
    const token = getToken();

    return fetchUtil({
        url: appendQueryParams(`/chat/${id}/messages`, {
            Limit: PAGINATION_LIMIT,
            ...paramsObj
        }),
        token,
        ...(signal && {
            signal
        })
    })
        .then((res) => {
            dispatch({
                type: CHAT_MESSAGES_SUCCESS,
                payload: res.ChatMessages.reverse(),
                totalMessages: res.TotalChatMessages,
                Page: paramsObj.Page
            });
            return Promise.resolve(res.ChatMessages);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return Promise.reject(false);
            }
            dispatch({
                type: CHAT_MESSAGES_FAIL
            });
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const addMessage = (data) => (dispatch) => {
    setTimeout(() => {
        scrollToBottom();
    }, 0);
    dispatch({
        type: ADD_MESSAGE,
        payload: data
    });
};
export const updateChatReadStatus = (data) => (dispatch) => {
    dispatch({
        type: UPDATE_CHAT_READ_STATUS,
        payload: data
    });
};
export const updateChatAfterAddingMessage = (data) => async (dispatch, getState) => {
    const {
        chatList: { list },
        currentChat: { data: currentChatData }
    } = getState().chat;
    let finalList;

    // if chat is available in the list
    if (isChatInList(list, data)) {
        // if chat is already on the top just update the last chat event
        if (list[0].Id === data.ChatId) {
            finalList = addLastChatEvent(data, list);
        } else {
            // otherwise move to top and update last chat event
            finalList = moveChatToTop(data, list);
        }
    } else {
        // if chat is not avaliable
        let newUserData;
        if (currentChatData.Id === data.ChatId) {
            // if its the current chat use this data
            newUserData = {
                ...currentChatData
            };
        } else {
            // else fetch the chat data
            newUserData = await dispatch(getChatById(data.ChatId));
        }
        finalList = addLastChatEvent(data, [newUserData, ...list]);
    }
    dispatch({
        type: MOVE_CHAT_TO_TOP,
        payload: finalList
    });
};

const moveChatToTop = (msgData, list) => {
    let clonedList = [...list];
    let chatCurrentIndex = clonedList.findIndex((chat) => chat.Id === msgData.ChatId);
    let [chatData] = clonedList.splice(chatCurrentIndex, 1);
    clonedList.unshift(chatData);
    return addLastChatEvent(msgData, clonedList);
};

const addLastChatEvent = (msgData, list) => {
    let data;
    if (msgData.Chat) {
        let { Chat, ...rest } = msgData;
        data = rest;
    } else {
        data = msgData;
    }
    return list.map((chat) => {
        if (chat.Id === data.ChatId) {
            return {
                ...chat,
                ...(msgData.Chat && {
                    ReadStatus: msgData.Chat.ReadStatus
                }),
                LastChatMessageId: data.Id,
                LastChatEvent: {
                    ...data
                }
            };
        }
        return {
            ...chat
        };
    });
};

const isChatInList = (list, data) => {
    return !!list.find((chat) => chat.Id === data.ChatId);
};

export const changeMessageAcknowledgement = (messageId, acknowledge, error) => {
    return {
        type: CHANGE_MESSAGE_ACKNOWLEDGEMENT,
        payload: {
            messageId,
            acknowledge,
            error
        }
    };
};

export const deleteMessage = (messageId) => {
    return {
        type: DELETE_MESSAGE,
        payload: {
            messageId
        }
    };
};

export const setPendingMessageError = () => {
    return {
        type: SET_MESSAGE_ERROR
    };
};

export const readChatMessage = (chatId, messageId) => (dispatch, getState) => {
    const token = getToken();
    fetchUtil({
        url: `/chat/${chatId}/read/${messageId}`,
        token,
        method: "PUT"
    })
        .then((res) => {
            dispatch({
                type: READ_MESSAGE_SUCCESS,
                payload: chatId
            });
        })
        .catch((err) => {});
};

export const changeCurrentChatContactStatus = (status) => {
    return {
        type: CHANGE_CURRENT_CHAT_CONTACT_STATUS,
        payload: status
    };
};

export const changeChatContactStatus = (contactId, status) => {
    return {
        type: CHANGE_CHAT_CONTACT_STATUS,
        payload: {
            contactId,
            status
        }
    };
};
export const getChatNotificationStatus = (contactId) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/chat/notification/history/${contactId}`,
        token,
        method: "GET"
    })
        .then((res) => {
            dispatch({
                type: CHAT_NOTIFICATION_STATUS,
                payload: res
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(false);
        });
};
