import React from "react";
import { connect } from "react-redux";
import moment from "moment";
import "../../App.css";
import { TagInputComponent } from "../../components/text";
import { MessageBox } from "../../components/MessageBox/index";
import { RoundCheckBox } from "../../components/checkbox/index";
import anchorme from "anchorme";
import {
    getContactsList,
    createCampaign,
    campaignCount,
    getContactsByCampaignId,
    getSegments,
    handleAutoReplyModal,
    handleTestMessageModal,
    imageUpload,
    getGiyf,
    sendTestMessage,
    getCampaignById,
    editCampaign
} from "../../redux/actions";
import { CampaignTypes, ContactStatus } from "../../config/constants";
import { getTimeZoneDatabaseName } from "../../utils/dateTimeUtils";
import { getObjectByKeys } from "../../utils/commonUtils";
import DatePicker from "react-datepicker";
import "../../../node_modules/react-datepicker/dist/react-datepicker.css";
import ConfirmationModal from "../AllContacts/ConfirmationModal";
import ContactAlertModal from "../../components/Contact/ContactAlertModal";
import { CustomModal } from "../../components/modal";
import { SuccessIcon } from "../../assets/svg-icon";
// import { Checkbox } from 'pretty-checkbox-react';
// import { SuccessIcon } from "../../assets/svg-icon";
import { AGE_GATED_STATUS } from "../../constant";
import { greenTick, infoIcon } from "../../assets/images";
import { Spinner } from "../../components/Loader";
const MESSAGE_MAX_LENGTH = 1000;
const SELECT_ALL_CONTACTS_TYPES = {
    COMPANY: 1,
    CAMPAIGN: 2,
    NO: 0,
    TEST_CAMPAIGN: 3
};
const REQUEST_REPLY_TYPE = {
    YES: 1,
    NO: 0
};

const LAST_STEP_TYPE = {
    SEND: "send",
    SCHDULE: "schdule",
    TEST: "test"
};
const STEPS = {
    CONTACT: "contact",
    MESSAGE: "message",
    REQUESTREPLY: "requestReply",
    AUTORESPONSE: "autoResponse",
    READY: "ready"
};
const STEPS_CLASS = {
    GRAYOUT: "Grey-Text",
    COMPLETE: "Green-Text",
    FOCUSED: "Focused-Text"
};

const CHECKBOX_SELECT = {
    YES: 1,
    NO: 2
};

class TextSmsCampaigns extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            doTheFunctionCall: false,
            messageName: "Test",
            messageText: "", //this is message text state
            requestReply: false, // show auto message container
            selectAllContacts: false,
            selectedContacts: {},
            selectedSegments: {},
            selectedTestCampaignContacts: {}, // these are contacts for sending test campaign
            segmentContacts: {},
            messageTextError: false,
            shortenUrlData: {},
            urlLoading: false,
            isLoading: false,
            campaignId: null,
            autoReplyMessageText: "", //this is reply message state
            autoReplyText: "Reply Y for yes",
            autoReplyMessageStatus: false,
            mediaObj: null,
            mediaBase64Url: null,
            autoReplyMediaObj: null,
            mediaUrl: "",
            autoReplyMediaUrl: "",
            clearImageFile: false,
            loaderSendCampaign: false,
            content: "",
            mediaObject: null,
            autoReplyMessagePanel: false, // show auto message box to write messsage
            activeSendItNow: true,
            activeSendTest: false,
            activeSchdule: false,
            testPhoneDetails: [],
            testPhoneInput: "",
            testPhoneInputError: false,
            scheduleDate: null, //both schdule are in use
            stepOneCompleted: false,
            isSubmitCallInitiated: false, //this gets true when use hit any last step button to submit form.
            confirmationModal: false,
            confirmationModalValues: {},
            resetAllComponentsData: false,
            showCampaignSucessAlertModal: false,
            focusedBox: "",
            editCampaign: false,
            editCampaignMediaUrl: null,
            editCampaignAutoResponseMediaUrl: null,
            editCampaignId: null,
            editCampaignIsMediaUpdate: false,
            editCampaignIsMediaUpdateAutoResponse: false,
            selectionType: SELECT_ALL_CONTACTS_TYPES.NO,
            testCampainSelectionType: SELECT_ALL_CONTACTS_TYPES.TEST_CAMPAIGN,
            radio: null,
            FilteredContactCount: 0,
            TotalContactCount: 0,
            ageGated: false,
            testCampaignSent: false,
            isFetchingCount: false
        };

        this.searchComponent = React.createRef();
    }
    onChangeRadioButton = (val) => {
        if (val === this.state.radio) {
            this.setState({
                ageGated: false,
                radio: null
            });
        } else if (val === CHECKBOX_SELECT.YES) {
            this.setState({
                ageGated: true,
                radio: CHECKBOX_SELECT.YES
            });
        } else {
            this.setState({
                ageGated: false,
                radio: CHECKBOX_SELECT.NO
            });
        }
        // this.setState({
        //     radio: event.target.value
        // });
        // if (event.target.value === "Yes") {
        //     this.setState({
        //         ageGated: true
        //     })
        // }
        // else {
        //     this.setState({
        //         ageGated: false
        //     })
        // }
    };
    updateMediaUrl = (mediaUrl) => {
        this.setState({
            mediaUrl: mediaUrl
        });
    };

    updateAutoReplyMediaUrl = (autoReplyMediaUrl) => {
        this.setState({
            autoReplyMediaUrl: autoReplyMediaUrl
        });
    };

    componentWillUnmount() {
        const element = document.getElementsByClassName("chatsnew-btn");
        element[0].style.display = "inline-block";

        const dropDown = document.getElementsByClassName("newbutton-dropdown");
        if (dropDown && dropDown[0]) {
            dropDown[0].style.display = "inline-block";
        }
    }

    componentDidMount() {
        const element = document.getElementsByClassName("chatsnew-btn");
        if (element && element[0]) {
            element[0].style.display = "none";
        }

        const dropDown = document.getElementsByClassName("newbutton-dropdown");
        if (dropDown && dropDown[0]) {
            dropDown[0].style.display = "none";
        }

        const { location, segments } = this.props;
        let campaignId = this.props.match.params.id;
        if (campaignId) {
            this.props.getCampaignById(campaignId).then((Campaign) => {
                console.log(Campaign.Meta);
                this.setState({
                    messageText: Campaign.Content,
                    autoReplyMessageStatus: Campaign.AutoReplyStatus ? true : false,
                    autoReplyMessagePanel:
                        (Campaign.AutoReplyTxt && Campaign.AutoReplyTxt !== "") ||
                        (Campaign.AutoMediaUrl && Campaign.AutoMediaUrl.length)
                            ? true
                            : false,
                    autoReplyMessageText: Campaign.AutoReplyTxt ? Campaign.AutoReplyTxt : "",
                    editCampaign: true,
                    editCampaignMediaUrl: Campaign.MediaUrl || null,
                    editCampaignAutoResponseMediaUrl: Campaign.AutoMediaUrl || null,
                    editCampaignId: Campaign.Id,
                });
                this.makeLastStepActive(LAST_STEP_TYPE.SCHDULE);
                this.resetSchduleDate(new Date(Campaign.SentOn));
                if (Campaign.Meta?.ContactIds) {
                    this.props.getContactsList({ OptStatus: ContactStatus.OPT_IN }).then((res) => {
                        console.log('getContactsList', res)
                        const selectedContacts = {};
                        for (let i = 0; i < res.Contacts.length; i++) {
                            const index = Campaign.Meta.ContactIds.indexOf(res.Contacts[i].Id);
                            if (index > -1) {
                                selectedContacts[res.Contacts[i].Id] = res.Contacts[i];
                            }
                        }
                        console.log(selectedContacts);
                        this.setState({ selectedContacts });
                    });
                }
                if (Campaign.Meta?.AllContacts === 1) {
                    this.setState({
                        selectionType: SELECT_ALL_CONTACTS_TYPES.COMPANY
                    })
                }
                if (Campaign.Meta?.SegmentIds) {
                    const selectedSegments = {};
                        for (let i = 0; i < segments.length; i++) {
                            const index = Campaign.Meta.SegmentIds.indexOf(segments[i].Id);
                            if (index > -1) {
                                selectedSegments[segments[i].Id] = segments[i];
                            }
                        }
                        this.setState({ selectedSegments });
                }
            });
        }
        if (location.state) {
            const { selectAllContacts, contactsToSend } = location.state;
            if (selectAllContacts) {
                let campaignId = location.state.campaignId;
                if (!!campaignId) {
                    this.setState({
                        selectAllContacts: true,
                        campaignId
                    });
                    this.props.getContactsByCampaignId(campaignId, {
                        FilteredType: location.state.filterType
                    });
                } else {
                    this.setState({ selectAllContacts: true, campaignId: null });
                    this.props.getContactsList({ OptStatus: ContactStatus.OPT_IN });
                }
            } else {
                let selectedContacts = getObjectByKeys(contactsToSend, "Id");
                this.setState({ selectedContacts });
            }
            this.props.history.replace();
        }
        if (!segments.length) {
            this.props.getSegments();
        }
    }
    handleChange = (e, text) => {
        if (text) {
            this.setState({
                messageText: text
            });
        } else {
            e.preventDefault();
            this.setState({
                messageText: e.target.value
            });
        }
    };
    clearFileInput = () => {
        this.setState({
            mediaObject: null,
            mediaBase64Url: null,
            mediaUrl: ""
        });
    };

    clearAutoReplyFileInput = () => {
        this.setState({
            autoReplyMediaObj: null,
            autoReplyMediaUrl: ""
        });
    };

    updateMediaObjectGiyf = ({ Obj }) => {
        this.setState({
            mediaUrl: Obj.url,
            editCampaignMediaUrl: null,
            editCampaignIsMediaUpdate: true
        });
        this.updateMediaBase64Url(Obj.url);
    };

    updateAutoReplyGiyf = ({ Obj }) => {
        this.setState({
            autoReplyMediaUrl: Obj.url,
            editCampaignAutoResponseMediaUrl: null,
            editCampaignIsMediaUpdateAutoResponse: true
        });
    };

    _handleImageChange = ({ Obj }) => {
        this.setState({ loader: true });
        this.setState({
            mediaObject: Obj,
            editCampaignIsMediaUpdate: true,
            editCampaignMediaUrl: null
        });
        let fileReader = new FileReader();
        fileReader.readAsDataURL(Obj);
        fileReader.onloadend = () => {
            this.updateMediaBase64Url(fileReader.result);
            this.setState({ loader: false });
        };
    };

    updateAutoReplyMediaObject = (Obj) => {
        this.setState({
            autoReplyMediaObj: Obj,
            editCampaignIsMediaUpdateAutoResponse: true,
            editCampaignAutoResponseMediaUrl: null
        });
    };
    updateLoaderSendCampaign = (data) => {
        this.setState({
            loaderSendCampaign: data
        });
    };

    updateMediaBase64Url = (url) => {
        this.setState({
            mediaBase64Url: url
        });
    };

    handleConfirmationModalChange = (show) => {
        this.setState({ confirmationModal: show });
    };

    autoReplyCloseHandler(status) {
        this.setState({ autoReplyMessageStatus: false });
        this.props.handleAutoReplyModal(false);
    }

    handleAutoReplyMessage = (e) => {
        this.setState({ autoReplyMessageText: e.target.value });
    };

    /**
     * @description This func is in use
     */
    updateSelectedSegment = (sagments) => {
        const { selectedContacts } = this.state;
        const selectedSegments = {};
        if (!sagments.length) {
            this.setState({
                selectedSegments,
                stepOneCompleted: !!Object.keys(selectedContacts).length
            });
            return;
        }
        sagments.map((sagment) => (selectedSegments[sagment.Id] = sagment));
        this.setState({
            selectedSegments,
            stepOneCompleted: true
        });
    };

    /**
     * @description This func is in use
     */
    updateSelectedContact = (contacts) => {
        const { selectedSegments } = this.state;
        const selectedContacts = {};
        if (!contacts.length) {
            this.setState({
                selectedContacts,
                stepOneCompleted: !!Object.keys(selectedSegments).length
            });
            return;
        }
        contacts.map((contact) => (selectedContacts[contact.Id] = contact));
        this.setState({
            selectedContacts,
            stepOneCompleted: true
        });
    };

    // to update the selected contacts for testing campaign
    updateTestCampaignSelectedContacts = (contacts) => {
        const selectedTestCampaignContacts = {};
        if (!contacts.length) {
            this.setState({
                selectedTestCampaignContacts
            });
            return;
        }
        contacts.map((contact) => (selectedTestCampaignContacts[contact.Id] = contact));
        this.setState({
            selectedTestCampaignContacts,
            testCampaignSent: false
        });
    };

    shouldSubmit = () => {
        const isStepOneCompleted = this.isStepOneCompleted();
        const isStepTwoCompleted = this.isStepTwoCompleted();
        const isStepThreeCompleted = this.isStepThreeCompleted();
        return isStepOneCompleted && isStepTwoCompleted && isStepThreeCompleted;
    };

    isStepOneCompleted = () => {
        const {
            selectedContacts,
            selectedSegments,
            activeSendTest,
            editCampaign,
            selectionType
        } = this.state;

        if (activeSendTest) {
            return true;
        }
        return (
            !!Object.keys(selectedContacts).length ||
            !!Object.keys(selectedSegments).length ||
            editCampaign ||
            selectionType === SELECT_ALL_CONTACTS_TYPES.COMPANY
        );
    };

    isStepTwoCompleted = () => {
        const { messageName, messageText, messageTextError } = this.state;
        return !!messageText.length && !messageTextError && !!messageName.length;
    };

    isStepThreeCompleted = () => {
        const { radio, ageGated } = this.state;
        if (radio === null && this.props.user.Company.AgeGated === 1) {
            return false;
        }
        if (ageGated) return !!radio;
        return true;
    };

    handleAgeGatedApi = () => {
        return new Promise((resolve, reject) => {
            const {
                messageName,
                messageText,
                requestReply,
                shortenUrlData,
                selectedSegments,
                autoReplyMessageText,
                mediaUrl,
                autoReplyMediaUrl,
                autoReplyMessagePanel,
                scheduleDate,
                editCampaign,
                editCampaignId,
                selectionType
            } = this.state;
            let selectedContacts = { ...this.state.selectedContacts };
            let currentTimezone = getTimeZoneDatabaseName();
            if (!this.shouldSubmit()) {
                return;
            }
            let AutoReplyStatus = 0;
            if (autoReplyMessagePanel) {
                AutoReplyStatus = 1;
            }
            this.setState({
                ...this.state,
                isFetchingCount: true
            });
            if (!editCampaign) {
                const bodyData = {
                    Title: messageName,
                    Content: messageText,
                    ConfirmationRequest: requestReply
                        ? REQUEST_REPLY_TYPE.YES
                        : REQUEST_REPLY_TYPE.NO,
                    SelectAllContacts: selectionType,
                    Type: CampaignTypes.SMS,
                    AutoReplyTxt: AutoReplyStatus === 1 ? autoReplyMessageText : "",
                    AutoReplyStatus: AutoReplyStatus,
                    // TODO WILL CHANGE THIS TO OUR MEDIA URL IF EXIST
                    MediaUrl: mediaUrl,
                    AutoMediaUrl: AutoReplyStatus === 1 ? autoReplyMediaUrl : null
                };

                if (shortenUrlData.Id) {
                    bodyData["UrlId"] = Number(shortenUrlData.Id);
                }
                if (
                    Object.keys(selectedSegments).length &&
                    selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
                ) {
                    let selectedSegmentIds = Object.keys(selectedSegments).map((id) => Number(id));
                    bodyData["SegmentIds"] = selectedSegmentIds;
                }
                if (
                    Object.keys(selectedContacts).length &&
                    selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
                ) {
                    let selectedContactIds = Object.keys(selectedContacts).map((id) => Number(id));
                    bodyData["ContactIds"] = selectedContactIds;
                }

                if (scheduleDate) {
                    let datend = moment(scheduleDate).toISOString();
                    let companyDateTime = moment(datend)
                        .tz(currentTimezone)
                        .toISOString();
                    let dateUtc = moment(companyDateTime)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss");
                    bodyData["SentOn"] = dateUtc;
                }
                if (this.state.radio === CHECKBOX_SELECT.YES) {
                    this.props
                        .campaignCount(bodyData)
                        .then((val) => {
                            this.setState({
                                FilteredContactCount: val.FilteredContactCount,
                                TotalContactCount: val.TotalContactCount,
                                doTheFunctionCall: true,
                                isFetchingCount: false
                            });
                            resolve({
                                FilteredContactCount: val.FilteredContactCount,
                                TotalContactCount: val.TotalContactCount,
                                doTheFunctionCall: true
                            });
                        })
                        .catch((e) => {
                            this.setState({
                                ...this.state,
                                isFetchingCount: false
                            });
                        });
                }
            } else {
                const bodyData = {
                    Id: editCampaignId,
                    Title: messageName,
                    Content: messageText,
                    ConfirmationRequest: requestReply
                        ? REQUEST_REPLY_TYPE.YES
                        : REQUEST_REPLY_TYPE.NO,
                    Type: CampaignTypes.SMS,
                    AutoReplyTxt: AutoReplyStatus === 1 ? autoReplyMessageText : "",
                    AutoReplyStatus: AutoReplyStatus,
                    // TODO WILL CHANGE THIS TO OUR MEDIA URL IF EXIST
                    MediaUrl: mediaUrl || this.state.editCampaignMediaUrl,
                    AutoMediaUrl:
                        AutoReplyStatus === 1
                            ? autoReplyMediaUrl || this.state.editCampaignAutoResponseMediaUrl
                            : null
                };
                if (scheduleDate) {
                    let datend = moment(scheduleDate).toISOString();
                    let companyDateTime = moment(datend)
                        .tz(currentTimezone)
                        .toISOString();
                    let dateUtc = moment(companyDateTime)
                        .utc()
                        .format("YYYY-MM-DD HH:mm:ss");
                    bodyData["SentOn"] = dateUtc;
                }
                if (this.state.radio === CHECKBOX_SELECT.YES) {
                    this.props
                        .campaignCount(bodyData)
                        .then((val) => {
                            this.setState({
                                FilteredContactCount: val.FilteredContactCount,
                                TotalContactCount: val.TotalContactCount,
                                isFetchingCount: false
                            });
                            resolve({
                                FilteredContactCount: val.FilteredContactCount,
                                TotalContactCount: val.TotalContactCount,
                                doTheFunctionCall: true
                            });
                        })
                        .catch((e) => {
                            this.setState({
                                isFetchingCount: false
                            });
                        });
                }
            }
        });
    };

    handleCampaignSend = (media = {}) => {
        const {
            messageName,
            messageText,
            requestReply,
            shortenUrlData,
            selectedSegments,
            autoReplyMessageText,
            mediaUrl,
            autoReplyMediaUrl,
            autoReplyMessagePanel,
            scheduleDate,
            editCampaign,
            editCampaignId,
            selectionType
        } = {
            ...this.state,
            ...media,
        };
        let selectedContacts = { ...this.state.selectedContacts };

        let currentTimezone = getTimeZoneDatabaseName();
        if (!this.shouldSubmit()) {
            return;
        }
        let AutoReplyStatus = 0;
        if (autoReplyMessagePanel) {
            AutoReplyStatus = 1;
        }
        if (!editCampaign) {
            const bodyData = {
                Title: messageName,
                Content: messageText,
                ConfirmationRequest: requestReply ? REQUEST_REPLY_TYPE.YES : REQUEST_REPLY_TYPE.NO,
                SelectAllContacts: selectionType,
                Type: CampaignTypes.SMS,
                AutoReplyTxt: AutoReplyStatus === 1 ? autoReplyMessageText : "",
                AutoReplyStatus: AutoReplyStatus,
                // TODO WILL CHANGE THIS TO OUR MEDIA URL IF EXIST
                MediaUrl: mediaUrl,
                AutoMediaUrl: AutoReplyStatus === 1 ? autoReplyMediaUrl : null,
                AgeGatedStatus: this.state.ageGated ? AGE_GATED_STATUS.Yes : AGE_GATED_STATUS.No
            };

            if (shortenUrlData.Id) {
                bodyData["UrlId"] = Number(shortenUrlData.Id);
            }
            if (
                Object.keys(selectedSegments).length &&
                selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
            ) {
                let selectedSegmentIds = Object.keys(selectedSegments).map((id) => Number(id));
                bodyData["SegmentIds"] = selectedSegmentIds;
            }
            if (
                Object.keys(selectedContacts).length &&
                selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
            ) {
                let selectedContactIds = Object.keys(selectedContacts).map((id) => Number(id));
                bodyData["ContactIds"] = selectedContactIds;
            }

            if (scheduleDate) {
                let datend = moment(scheduleDate).toISOString();
                let companyDateTime = moment(datend)
                    .tz(currentTimezone)
                    .toISOString();
                let dateUtc = moment(companyDateTime)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                bodyData["SentOn"] = dateUtc;
            }
            // todo api
            this.setState({ isLoading: true });
            this.props
                .createCampaign(bodyData)
                .then((res) => {
                    console.log(
                        "🚀 ~ file: TextSmsCampaigns.js ~ line 623 ~ TextSmsCampaigns ~ .then ~ res",
                        res
                    );
                    this.setState({ showCampaignSucessAlertModal: true });
                    if (!!shortenUrlData.clearUrlText) {
                        shortenUrlData.clearUrlText();
                    }
                    this.setState({
                        selectAllContacts: false,
                        selectedContacts: {},
                        selectedSegments: {},
                        messageText: "",
                        requestReply: false,
                        shortenUrlData: {},
                        campaignId: null,
                        autoReplyMessageStatus: false,
                        autoReplyMessageText: "",
                        mediaObj: null,
                        mediaBase64Url: null,
                        autoReplyMediaObj: null,
                        mediaUrl: "",
                        autoReplyMediaUrl: "",
                        clearImageFile: true,
                        loaderSendCampaign: false,
                        stepOneCompleted: false,
                        isSubmitCallInitiated: false,
                        confirmationModal: false,
                        autoReplyMessagePanel: false,
                        resetAllComponentsData: true,
                        scheduleDate: null,
                        testPhoneInput: ""
                    });
                    this.setState({ isLoading: false, resetAllComponentsData: true });
                    this.handleConfirmationModalChange(false);
                })
                .catch((e) => {
                    console.log("THIS IS ERROR*************************", e);
                    this.setState({ isLoading: false})
                })
                .finally(() => {
                    this.handleConfirmationModalChange(false);
                    this.updateLoaderSendCampaign(false);
                });
        } else {
            const bodyData = {
                Id: editCampaignId,
                Title: messageName,
                Content: messageText,
                ConfirmationRequest: requestReply ? REQUEST_REPLY_TYPE.YES : REQUEST_REPLY_TYPE.NO,
                Type: CampaignTypes.SMS,
                AutoReplyTxt: AutoReplyStatus === 1 ? autoReplyMessageText : "",
                AutoReplyStatus: AutoReplyStatus,
                SelectAllContacts: selectionType,
                // TODO WILL CHANGE THIS TO OUR MEDIA URL IF EXIST
                MediaUrl: mediaUrl || this.state.editCampaignMediaUrl,
                AutoMediaUrl:
                    AutoReplyStatus === 1
                        ? autoReplyMediaUrl || this.state.editCampaignAutoResponseMediaUrl
                        : null,
                AgeGatedStatus: this.state.ageGated ? AGE_GATED_STATUS.Yes : AGE_GATED_STATUS.No
            };
            if (
                Object.keys(selectedSegments).length &&
                selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
            ) {
                let selectedSegmentIds = Object.keys(selectedSegments).map((id) => Number(id));
                bodyData["SegmentIds"] = selectedSegmentIds;
            }
            if (
                Object.keys(selectedContacts).length &&
                selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
            ) {
                let selectedContactIds = Object.keys(selectedContacts).map((id) => Number(id));
                bodyData["ContactIds"] = selectedContactIds;
            }
            if (scheduleDate) {
                let datend = moment(scheduleDate).toISOString();
                let companyDateTime = moment(datend)
                    .tz(currentTimezone)
                    .toISOString();
                let dateUtc = moment(companyDateTime)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                bodyData["SentOn"] = dateUtc;
            }
            this.setState({ isLoading: true });
            this.props
                .editCampaign(bodyData)
                .then((res) => {
                    this.setState({ showCampaignSucessAlertModal: true });
                    if (!!shortenUrlData.clearUrlText) {
                        shortenUrlData.clearUrlText();
                    }
                    this.setState({
                        selectAllContacts: false,
                        selectedContacts: {},
                        selectedSegments: {},
                        messageText: "",
                        requestReply: false,
                        shortenUrlData: {},
                        campaignId: null,
                        autoReplyMessageStatus: false,
                        autoReplyMessageText: "",
                        mediaObj: null,
                        mediaBase64Url: null,
                        autoReplyMediaObj: null,
                        mediaUrl: "",
                        autoReplyMediaUrl: "",
                        clearImageFile: true,
                        loaderSendCampaign: false,
                        stepOneCompleted: false,
                        isSubmitCallInitiated: false,
                        confirmationModal: false,
                        autoReplyMessagePanel: false,
                        resetAllComponentsData: true,
                        scheduleDate: null,
                        testPhoneInput: ""
                    });
                    this.setState({ isLoading: false, resetAllComponentsData: true });
                    this.handleConfirmationModalChange(false);
                })
                .catch((e) => {
                    console.log("THIS IS ERROR*************************", e);
                    this.setState({ isLoading: false})
                })
                .finally(() => {
                    this.handleConfirmationModalChange(false);
                    this.updateLoaderSendCampaign(false);
                });
        }
    };

    showAutoResponseHandle = (value) => {
        this.setState({ requestReply: value });
    };

    showAutoResponseMessagePanel = () => {
        let { autoReplyMessagePanel } = this.state;
        this.setState({ autoReplyMessagePanel: !autoReplyMessagePanel });
    };

    updateContentTextHandler = (text) => {
        if (text.length < MESSAGE_MAX_LENGTH) {
            this.setState({ messageText: `${text}` });
        }
    };

    updateAutoReplyTextHandler = (text) => {
        if (text.length < MESSAGE_MAX_LENGTH) {
            this.setState({ autoReplyMessageText: `${text}` });
        }
    };

    makeLastStepActive = (type) => {
        const { selectedContacts, selectedSegments } = this.state;
        if (type === LAST_STEP_TYPE.SEND) {
            this.setState({
                activeSchdule: false,
                activeSendItNow: true,
                activeSendTest: false,
                scheduleDate: null,
                stepOneCompleted:
                    !!Object.keys(selectedContacts).length || !!Object.keys(selectedSegments).length
            });
        } else if (type === LAST_STEP_TYPE.TEST) {
            this.setState({
                activeSchdule: false,
                activeSendItNow: false,
                activeSendTest: true,
                scheduleDate: null,
                stepOneCompleted: true
            });
        } else if (type === LAST_STEP_TYPE.SCHDULE) {
            const date = new Date();
            this.setState({
                activeSchdule: true,
                activeSendItNow: false,
                activeSendTest: false,
                scheduleDate: date,
                stepOneCompleted:
                    !!Object.keys(selectedContacts).length || !!Object.keys(selectedSegments).length
            });
        }
    };

    handleSendTest = (media = {}) => {
        // OLD IMPLEMENTATION FOR SENDING TEST MESSAGES
        // let { messageText, testPhoneDetails, requestReply, mediaUrl, editCampaignMediaUrl } = this.state;
        // testPhoneDetails = this.getActiveTestPhoneInputs()
        // testPhoneDetails = testPhoneDetails.map((testPhoneObject) => {
        //     return testPhoneObject.phoneNumber.replace(/[\s()-]/g, "");
        // })

        // const bodyData = {
        //     Content: messageText,
        //     ConfirmationRequest: requestReply ? REQUEST_REPLY_TYPE.YES : REQUEST_REPLY_TYPE.NO,
        //     PhoneNumber: testPhoneDetails,
        //     MediaUrl: mediaUrl || editCampaignMediaUrl,
        // };
        // this.props
        //     .sendTestMessage(bodyData)
        //     .then((res) => {
        //         this.setState({ loading: false })
        //         this.handleConfirmationModalChange(false);
        //     }).finally(() => {
        //         this.setState({ isLoading: false });
        //     });

        // NEW IMPLEMENTATION**********************************
        const {
            messageName,
            messageText,
            requestReply,
            shortenUrlData,
            autoReplyMessageText,
            mediaUrl,
            autoReplyMediaUrl,
            autoReplyMessagePanel,
            scheduleDate,
            editCampaign,
            editCampaignId,
            ageGated
        } = {
            ...this.state,
            ...media,
        };
        let selectedContacts = { ...this.state.selectedTestCampaignContacts };

        let currentTimezone = getTimeZoneDatabaseName();
        if (!this.shouldSubmit()) {
            return;
        }
        let AutoReplyStatus = 0;
        if (autoReplyMessagePanel) {
            AutoReplyStatus = 1;
        }
        if (!editCampaign) {
            const bodyData = {
                Title: messageName,
                Content: messageText,
                ConfirmationRequest: requestReply ? REQUEST_REPLY_TYPE.YES : REQUEST_REPLY_TYPE.NO,
                SelectAllContacts: SELECT_ALL_CONTACTS_TYPES.NO, // For test message, never select all contacts.
                Type: CampaignTypes.SMS,
                AutoReplyTxt: AutoReplyStatus === 1 ? autoReplyMessageText : "",
                AutoReplyStatus: AutoReplyStatus,
                // TODO WILL CHANGE THIS TO OUR MEDIA URL IF EXIST
                MediaUrl: mediaUrl,
                AutoMediaUrl: AutoReplyStatus === 1 ? autoReplyMediaUrl : null,
                AgeGatedStatus: ageGated ? AGE_GATED_STATUS.Yes : AGE_GATED_STATUS.No
            };

            if (shortenUrlData.Id) {
                bodyData["UrlId"] = Number(shortenUrlData.Id);
            }

            if (Object.keys(selectedContacts).length) {
                let selectedContactIds = Object.keys(selectedContacts).map((id) => Number(id));
                bodyData["ContactIds"] = selectedContactIds;
            }

            if (scheduleDate) {
                let datend = moment(scheduleDate).toISOString();
                let companyDateTime = moment(datend)
                    .tz(currentTimezone)
                    .toISOString();
                let dateUtc = moment(companyDateTime)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                bodyData["SentOn"] = dateUtc;
            }
            // todo api
            this.setState({ isLoading: true });
            this.props
                .createCampaign(bodyData)
                .then((res) => {
                    this.setState({ testCampaignSent: true });
                    if (!!shortenUrlData.clearUrlText) {
                        shortenUrlData.clearUrlText();
                    }
                })
                .finally(() => {
                    this.setState({ testCampaignSent: true, confirmationModal: false });
                    this.updateLoaderSendCampaign(false);
                    this.setState({ isLoading: false });
                })
                .catch((e) => {
                    this.setState({ testCampaignSent: false });
                });
        } else {
            const bodyData = {
                Id: editCampaignId,
                Title: messageName,
                Content: messageText,
                ConfirmationRequest: requestReply ? REQUEST_REPLY_TYPE.YES : REQUEST_REPLY_TYPE.NO,
                Type: CampaignTypes.SMS,
                AutoReplyTxt: AutoReplyStatus === 1 ? autoReplyMessageText : "",
                AutoReplyStatus: AutoReplyStatus,
                // TODO WILL CHANGE THIS TO OUR MEDIA URL IF EXIST
                MediaUrl: mediaUrl || this.state.editCampaignMediaUrl,
                AutoMediaUrl:
                    AutoReplyStatus === 1
                        ? autoReplyMediaUrl || this.state.editCampaignAutoResponseMediaUrl
                        : null,
                AgeGatedStatus: ageGated ? AGE_GATED_STATUS.Yes : AGE_GATED_STATUS.No
            };
            if (scheduleDate) {
                let datend = moment(scheduleDate).toISOString();
                let companyDateTime = moment(datend)
                    .tz(currentTimezone)
                    .toISOString();
                let dateUtc = moment(companyDateTime)
                    .utc()
                    .format("YYYY-MM-DD HH:mm:ss");
                bodyData["SentOn"] = dateUtc;
            }
            this.setState({ isLoading: true });
            this.props
                .editCampaign(bodyData)
                .then((res) => {
                    this.setState({ showCampaignSucessAlertModal: true });
                    if (!!shortenUrlData.clearUrlText) {
                        shortenUrlData.clearUrlText();
                    }
                })
                .finally(() => {
                    this.handleConfirmationModalChange(false);
                    this.updateLoaderSendCampaign(false);
                    this.setState({
                        isLoading: false,
                        resetAllComponentsData: false,
                        confirmationModal: false
                    });
                });
        }
    };
    getActiveTestPhoneInputs() {
        let { testPhoneDetails } = this.state;
        testPhoneDetails = testPhoneDetails.filter((testPhoneObject) => {
            if (testPhoneObject.active) {
                return true;
            }
            return false;
        });
        return testPhoneDetails;
    }
    getActiveSelectedTestContacts() {
        let { selectedTestCampaignContacts } = this.state;
        return Object.keys(selectedTestCampaignContacts);
    }
    addTestPhoneListHandler = () => {
        const { testPhoneDetails, testPhoneInput } = this.state;
        if (testPhoneInput.indexOf("_") !== -1) {
            this.setState({ testPhoneInputError: true });
            return;
        }
        for (const testPhone of testPhoneDetails) {
            if (testPhone.phoneNumber === testPhoneInput) {
                this.setState({ testPhoneInputError: true });
                return;
            }
        }
        const updPhoneDetails = [
            ...testPhoneDetails,
            { phoneNumber: testPhoneInput, active: true }
        ];
        this.setState({
            testPhoneDetails: updPhoneDetails,
            testPhoneInput: ""
        });
    };

    handlePhoneInputChange = (e) => {
        this.setState({
            testPhoneInput: e.target.value,
            testPhoneInputError: false
        });
    };

    resetSchduleDate = (date) => {
        this.setState({
            scheduleDate: date
        });
    };

    onModalConfirmation = async () => {
        const { activeSendTest, mediaObject, autoReplyMediaObj } = this.state;
        const media = {};
        this.setState({
            isLoading: true
        });
        if (!mediaObject && !autoReplyMediaObj) {
            activeSendTest ? this.handleSendTest() : this.handleCampaignSend();
            return;
        }
        if (mediaObject) {
            let formData = new FormData();
            formData.append("file", mediaObject);
            const image = await this.props.imageUpload(formData);
            media.mediaUrl = image.location;
            this.setState({
                mediaUrl: image.location
            });
        }
        if (autoReplyMediaObj) {
            let formData = new FormData();
            formData.append("file", autoReplyMediaObj.Obj);
            const image = await this.props.imageUpload(formData);
            media.autoReplyMediaUrl = image.location;
            this.setState({
                autoReplyMediaUrl: image.location
            });
        }
        activeSendTest ? this.handleSendTest(media) : this.handleCampaignSend(media);
    };

    renderConfirmationModal = () => {
        const { confirmationModalValues, isLoading } = this.state;
        return (
            <ConfirmationModal
                color={confirmationModalValues.color}
                handleModalClick={() => this.onModalConfirmation()}
                handleModalClose={() => this.handleConfirmationModalChange(false)}
                title={confirmationModalValues.modalTitle}
                content={confirmationModalValues.messageText}
                buttonContent={confirmationModalValues.buttonText}
                startLoading={isLoading}
                buttonDisabled={confirmationModalValues.isDisableSendButton}
            />
        );
    };
    // active & inactive testPhoneDetail
    changeTestPhoneDetailStatus = (phone) => {
        let { testPhoneDetails } = this.state;
        testPhoneDetails = testPhoneDetails.map((testPhoneObject) => {
            if (testPhoneObject.phoneNumber === phone) {
                testPhoneObject.active = !testPhoneObject.active;
            }
            return testPhoneObject;
        });
        this.setState({ testPhoneDetails });
    };
    // do work
    handleSendButton = async (actionType) => {
        let { editCampaign } = this.state;
        const isStepOneCompleted = this.isStepOneCompleted();
        const isStepTwoCompleted = this.isStepTwoCompleted();
        const isStepThreeCompleted = this.isStepThreeCompleted();
        this.setState({
            stepOneCompleted: isStepOneCompleted || editCampaign,
            stepTwoCompleted: isStepTwoCompleted,
            stepThreeCompleted: isStepThreeCompleted,
            isSubmitCallInitiated: true
        });
        if (
            isStepOneCompleted &&
            isStepTwoCompleted &&
            isStepThreeCompleted &&
            this.state.ageGated &&
            (actionType === LAST_STEP_TYPE.SEND || actionType === LAST_STEP_TYPE.SCHDULE)
        ) {
            let ageGatedApiResponse = await this.handleAgeGatedApi();
            if (
                ageGatedApiResponse &&
                ageGatedApiResponse.hasOwnProperty("FilteredContactCount") &&
                ageGatedApiResponse.hasOwnProperty("TotalContactCount")
            ) {
                this.validateAndPerformAction(
                    actionType,
                    isStepOneCompleted,
                    isStepTwoCompleted,
                    isStepThreeCompleted,
                    ageGatedApiResponse.FilteredContactCount,
                    ageGatedApiResponse.TotalContactCount
                );
            }
        } else {
            this.validateAndPerformAction(
                actionType,
                isStepOneCompleted,
                isStepTwoCompleted,
                isStepThreeCompleted
            );
        }
    };
    validateAndPerformAction = (
        actionType,
        isStepOneCompleted,
        isStepTwoCompleted,
        isStepThreeCompleted,
        FilteredContactCount,
        TotalContactCount
    ) => {
        let { editCampaign, ageGated } = this.state;
        let confirmationModalValues = {};
        if (isStepOneCompleted && isStepTwoCompleted && isStepThreeCompleted && !editCampaign) {
            if (actionType === LAST_STEP_TYPE.SEND && !ageGated) {
                confirmationModalValues = {
                    messageText:
                        "Are you sure you'd like to send this message now? This can not be undone.",
                    modalTitle: "Send Campaign Now",
                    buttonText: "Send",
                    color: "green"
                };
            } else if (actionType === LAST_STEP_TYPE.SEND && ageGated) {
                confirmationModalValues = {
                    messageText: `Your campaign will be sent to ${FilteredContactCount} out of ${TotalContactCount} contacts that have opted in to receive age-gated content. Are you sure you want to send it now?`,
                    modalTitle: "Send Campaign Now",
                    buttonText: "Send",
                    color: "green"
                };
            } else if (actionType === LAST_STEP_TYPE.SCHDULE && !ageGated) {
                const { scheduleDate } = this.state;
                const formatDate = moment(scheduleDate).format("LLLL");
                confirmationModalValues = {
                    messageText: `Are you sure you'd like to schedule this message to send at: ${formatDate}.`,
                    modalTitle: "Schedule Message",
                    buttonText: "Schedule",
                    color: "blue"
                };
            } else if (actionType === LAST_STEP_TYPE.SCHDULE && ageGated) {
                confirmationModalValues = {
                    messageText: `Your campaign will be sent to ${FilteredContactCount} out of ${TotalContactCount} contacts that have opted in to receive age-gated content. Are you sure you want to send it now?`,
                    modalTitle: "Schedule Message",
                    buttonText: "Schedule",
                    color: "blue"
                };
            } else if (actionType === LAST_STEP_TYPE.TEST) {
                let activeTestPhoneContacts = this.getActiveSelectedTestContacts();
                confirmationModalValues = {
                    messageText: `Are you sure you'd like to send this a test message now to ${
                        activeTestPhoneContacts.length
                    } contact${activeTestPhoneContacts.length > 1 ? `s` : ``}?`,
                    modalTitle: "Send Test Message",
                    buttonText: "Send Test",
                    color: "blue"
                };
            }
            this.setState({
                confirmationModalValues: {
                    ...confirmationModalValues,
                    isDisableSendButton:
                        ageGated && parseInt(FilteredContactCount) === 0 ? true : false
                },
                confirmationModal: true
            });
        } else if (
            isStepOneCompleted &&
            isStepTwoCompleted &&
            isStepThreeCompleted &&
            editCampaign
        ) {
            if (actionType === LAST_STEP_TYPE.SEND && !ageGated) {
                confirmationModalValues = {
                    messageText:
                        "Are you sure you'd like to update and send this message now? This can not be undone.",
                    modalTitle: "Send Campaign Now",
                    buttonText: "Send",
                    color: "green"
                };
            } else if (actionType === LAST_STEP_TYPE.SEND && ageGated) {
                confirmationModalValues = {
                    messageText: `Your campaign will be sent to ${FilteredContactCount} out of ${TotalContactCount} contacts that have opted in to receive age-gated content. Are you sure you want to send it now?`,
                    modalTitle: "Send Campaign Now",
                    buttonText: "Send",
                    color: "green"
                };
            } else if (actionType === LAST_STEP_TYPE.SCHDULE && !ageGated) {
                const { scheduleDate } = this.state;
                const formatDate = moment(scheduleDate).format("LLLL");
                confirmationModalValues = {
                    messageText: `Are you sure you'd like to override schedule this message to send at: ${formatDate}.`,
                    modalTitle: "Schedule Message",
                    buttonText: "Schedule",
                    color: "blue"
                };
            } else if (actionType === LAST_STEP_TYPE.SCHDULE && ageGated) {
                // const { scheduleDate } = this.state;
                // const formatDate = moment(scheduleDate).format("LLLL");
                confirmationModalValues = {
                    messageText: `Your campaign will be sent to ${FilteredContactCount} out of ${TotalContactCount} contacts that have opted in to receive age-gated content. Are you sure you want to send it now?`,
                    modalTitle: "Schedule Message",
                    buttonText: "Schedule",
                    color: "blue"
                };
            } else if (actionType === LAST_STEP_TYPE.TEST) {
                let activeTestPhoneContacts = this.getActiveSelectedTestContacts();
                confirmationModalValues = {
                    messageText: `Are you sure you'd like to send this a test message now to ${
                        activeTestPhoneContacts.length
                    } contact${activeTestPhoneContacts.length > 1 ? `s` : ``}?`,
                    modalTitle: "Send Test Message",
                    buttonText: "Send Test",
                    color: "blue"
                };
            }
            this.setState({
                confirmationModalValues: {
                    ...confirmationModalValues,
                    isDisableSendButton:
                        ageGated && parseInt(FilteredContactCount) === 0 ? true : false
                },
                confirmationModal: true
            });
        }
    };
    handleFoucus = (FocusKey) => {
        this.setState({ focusedBox: FocusKey });
    };
    checkValidationForMessageBorder = (isSubmitCallInitiated) => {
        const { radio } = this.state;
        if (isSubmitCallInitiated) {
            if (radio === null && this.props.user.Company.AgeGated === 1) {
                return true;
            }
            return false;
        } else {
            return false;
        }
    };
    checkValidation = (key) => {
        const {
            selectedContacts,
            selectedSegments,
            focusedBox,
            messageText,
            requestReply,
            autoReplyMessageText,
            autoReplyMessagePanel,
            editCampaign,
            selectionType
        } = this.state;
        if (key === STEPS.CONTACT) {
            if (editCampaign) {
                return STEPS_CLASS.COMPLETE;
            }
            if (
                Object.keys(selectedContacts).length === 0 &&
                Object.keys(selectedSegments).length === 0 &&
                selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
            ) {
                if (focusedBox === key) {
                    return STEPS_CLASS.FOCUSED;
                } else {
                    return STEPS_CLASS.GRAYOUT;
                }
            } else {
                return STEPS_CLASS.COMPLETE;
            }
        } else if (key === STEPS.MESSAGE) {
            const { radio } = this.state;

            if (radio === null && this.props.user.Company.AgeGated === 1) {
                return STEPS_CLASS.GRAYOUT;
            }
            if (
                selectedContacts.length === 0 &&
                selectedSegments.length === 0 &&
                !editCampaign &&
                selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY
            ) {
                return STEPS_CLASS.GRAYOUT;
            }
            if (autoReplyMessageText.length === 0) {
                return STEPS_CLASS.GRAYOUT;
            }
            if (messageText.length > 0) {
                return STEPS_CLASS.COMPLETE;
            } else if (focusedBox === key) {
                return STEPS_CLASS.FOCUSED;
            } else {
                return STEPS_CLASS.GRAYOUT;
            }
        } else if (key === STEPS.REQUESTREPLY) {
            if (
                (Object.keys(selectedContacts).length === 0 &&
                    Object.keys(selectedSegments).length === 0 &&
                    !editCampaign &&
                    selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY) ||
                messageText.length === 0
            ) {
                return STEPS_CLASS.GRAYOUT;
            }
            if (requestReply) {
                return STEPS_CLASS.COMPLETE;
            } else {
                return STEPS_CLASS.GRAYOUT;
            }
        } else if (key === STEPS.AUTORESPONSE) {
            if (
                (Object.keys(selectedContacts).length === 0 &&
                    Object.keys(selectedSegments).length === 0 &&
                    !editCampaign) ||
                messageText.length === 0
            ) {
                return STEPS_CLASS.GRAYOUT;
            }
            if (autoReplyMessagePanel && autoReplyMessageText.length !== 0) {
                return STEPS_CLASS.COMPLETE;
            } else if (focusedBox === key) {
                return STEPS_CLASS.FOCUSED;
            } else if (autoReplyMessagePanel && autoReplyMessageText.length === 0) {
                return STEPS_CLASS.GRAYOUT;
            } else if (!autoReplyMessagePanel) {
                return STEPS_CLASS.GRAYOUT;
            }
        } else if (key === STEPS.READY) {
            if (
                (Object.keys(selectedContacts).length === 0 &&
                    Object.keys(selectedSegments).length === 0 &&
                    !editCampaign &&
                    selectionType !== SELECT_ALL_CONTACTS_TYPES.COMPANY) ||
                (autoReplyMessagePanel && autoReplyMessageText.length === 0) ||
                messageText.length === 0
            ) {
                return STEPS_CLASS.GRAYOUT;
            }
            if (autoReplyMessagePanel && autoReplyMessageText.length !== 0) {
                return STEPS_CLASS.COMPLETE;
            } else {
                return STEPS_CLASS.FOCUSED;
            }
        }
    };
    clearMediaUrl = () => {
        this.setState({
            editCampaignMediaUrl: null
        });
    };
    clearAutoResponseMediaUrl = () => {
        this.setState({
            editCampaignAutoResponseMediaUrl: null
        });
    };
    updateAllContactSelection = (flag) => {
        this.setState({
            selectionType: flag
        });
    };
    render() {
        let {
            selectedContacts,
            selectedSegments,
            autoReplyMessagePanel,
            requestReply,
            activeSchdule,
            activeSendItNow,
            activeSendTest,
            // testPhoneInput,
            scheduleDate,
            stepOneCompleted,
            isSubmitCallInitiated,
            confirmationModal,
            resetAllComponentsData,
            mediaBase64Url,
            // testPhoneDetails,
            messageText,
            editCampaign,
            editCampaignMediaUrl,
            editCampaignIsMediaUpdate,
            editCampaignAutoResponseMediaUrl,
            autoReplyMessageText,
            editCampaignIsMediaUpdateAutoResponse,
            autoReplyMessageStatus,
            selectedTestCampaignContacts,
            isFetchingCount
        } = this.state;
        const achromeText = anchorme(messageText);
        const { user } = this.props;
        return (
            <div className="text-container">
                <div className="chats-newcampaign-modal">
                    <div className="modal-content newcampaign-modals">
                        {confirmationModal ? this.renderConfirmationModal() : null}
                        <div className="setting-tab-content">
                            <div className="row">
                                <div
                                    className="w-8 scroll-styling"
                                    style={{ height: "calc(100vh - 5.0625rem)", overflow: "auto" }}
                                >
                                    <div className="newcampaign-modal-form">
                                        <h3>New Campaign</h3>
                                        <form>
                                            {/* step 1 */}
                                            <div className="row">
                                                <div className="w-2-5">
                                                    <div
                                                        className={`new-campaign-panebar ${this.checkValidation(
                                                            STEPS.CONTACT
                                                        )}`}
                                                        style={{
                                                            borderColor:
                                                                !stepOneCompleted &&
                                                                isSubmitCallInitiated
                                                                    ? "#dc3545"
                                                                    : "#3AAF50"
                                                        }}
                                                    >
                                                        <span
                                                            className={`${this.checkValidation(
                                                                STEPS.CONTACT
                                                            )}`}
                                                        >
                                                            Recipients
                                                        </span>
                                                    </div>
                                                </div>
                                                <div className="w-9-5 newcampaign-modal-form-right newcampaign-modal-form-vertical-spacing">
                                                    <label
                                                        className={`${this.checkValidation(
                                                            STEPS.CONTACT
                                                        )}`}
                                                    >
                                                        To:
                                                    </label>
                                                    <TagInputComponent
                                                        verticalSpacing={0}
                                                        segments={this.props.segments}
                                                        updateContact={this.updateSelectedContact}
                                                        updateSagments={this.updateSelectedSegment}
                                                        selectedSegments={selectedSegments}
                                                        selectedContacts={selectedContacts}
                                                        resetTagInput={resetAllComponentsData}
                                                        onFocus={() =>
                                                            this.handleFoucus(STEPS.CONTACT)
                                                        }
                                                        onBlur={() => this.handleFoucus("")}
                                                        editCampaign={editCampaign}
                                                        allContactSelected={editCampaign}
                                                        focusedBox={this.state.focusedBox}
                                                        selectionType={this.state.selectionType}
                                                        updateAllContactSelection={
                                                            this.updateAllContactSelection
                                                        }
                                                    />
                                                    {!stepOneCompleted && isSubmitCallInitiated && (
                                                        <p className="text-sms-campaign-validation-error">
                                                            Please select contact or segment. It is
                                                            required field
                                                        </p>
                                                    )}
                                                </div>
                                            </div>
                                            {/* step 2 */}
                                            <div className="row">
                                                <div className="w-2-5">
                                                    <div
                                                        className={`new-campaign-panebar ${this.checkValidation(
                                                            STEPS.MESSAGE
                                                        )}`}
                                                        style={{
                                                            borderColor:
                                                                !messageText &&
                                                                isSubmitCallInitiated
                                                                    ? "#dc3545"
                                                                    : "#3AAF50"
                                                        }}
                                                    >
                                                        <span
                                                            className={`${this.checkValidation(
                                                                STEPS.MESSAGE
                                                            )}`}
                                                        >
                                                            Message
                                                        </span>
                                                    </div>
                                                </div>
                                                <div
                                                    className="w-9-5 newcampaign-modal-form-right newcampaign-modal-form-vertical-spacing"
                                                    onFocus={() => this.handleFoucus(STEPS.MESSAGE)}
                                                    onBlur={() => this.handleFoucus("")}
                                                >
                                                    <label
                                                        className={`${this.checkValidation(
                                                            STEPS.MESSAGE
                                                        )}`}
                                                    >
                                                        Message body :
                                                    </label>
                                                    <MessageBox
                                                        textAreaRows={10}
                                                        handleChange={this.handleChange}
                                                        clearFileInput={this.clearFileInput}
                                                        _handleImageChange={this._handleImageChange}
                                                        updateMediaObjectGiyf={
                                                            this.updateMediaObjectGiyf
                                                        }
                                                        showAutoResponseHandle={
                                                            this.showAutoResponseHandle
                                                        }
                                                        showAutoResponseToggle={true}
                                                        resetInput={resetAllComponentsData}
                                                        updateTextState={
                                                            this.updateContentTextHandler
                                                        }
                                                        addDefualtText={messageText}
                                                        addDefualtRequestReply={
                                                            autoReplyMessageStatus
                                                        }
                                                        mediaUrl={editCampaignMediaUrl}
                                                        clearMediaUrl={this.clearMediaUrl}
                                                        isMediaUpdate={editCampaignIsMediaUpdate}
                                                    />
                                                </div>
                                            </div>

                                            {/* <div className="new-campaign-panebar-request-reply">
                                                <div className="w-2-5">
                                                    <div className={`new-campaign-panebar ${this.checkValidation(STEPS.REQUESTREPLY)}`} style={{ borderColor: (!messageText && isSubmitCallInitiated ? '#dc3545' : '#3AAF50') }}>
                                                        <span className={`${this.checkValidation(STEPS.REQUESTREPLY)}`}>Request reply</span>
                                                    </div>
                                                </div>
                                            </div> */}

                                            {/* mehdi working */}
                                            {requestReply ? (
                                                <div className="row">
                                                    <div className="w-2-5">
                                                        <div
                                                            className={`new-campaign-br-only`}
                                                            style={{
                                                                borderColor:
                                                                    !autoReplyMessageText &&
                                                                    isSubmitCallInitiated
                                                                        ? "#dc3545"
                                                                        : "#3AAF50"
                                                            }}
                                                        >
                                                            <span
                                                                className={`${this.checkValidation(
                                                                    STEPS.AUTORESPONSE
                                                                )}`}
                                                            >
                                                                {" "}
                                                                Auto Response{" "}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="w-9-5 newcampaign-modal-form-right newcampaign-modal-form-vertical-spacing">
                                                        <div className="setting-modal-form">
                                                            <div className="row">
                                                                <div className="w-6">
                                                                    <div className="auto-reply-msg text-left">
                                                                        <label
                                                                            className={`${this.checkValidation(
                                                                                STEPS.AUTORESPONSE
                                                                            )}`}
                                                                        >
                                                                            Auto-Response for Yes
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div className="w-6">
                                                                    <div className="auto-reply-btn">
                                                                        <div className="toggleswitching">
                                                                            <input
                                                                                type="checkbox"
                                                                                id="toggleswitch4"
                                                                                className="togglecheckbox"
                                                                                selected=""
                                                                                checked={
                                                                                    autoReplyMessagePanel
                                                                                }
                                                                                onClick={
                                                                                    this
                                                                                        .showAutoResponseMessagePanel
                                                                                }
                                                                            />
                                                                            <label
                                                                                htmlFor="toggleswitch4"
                                                                                className="toggleswitch"
                                                                            ></label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            {autoReplyMessagePanel ? (
                                                                <span
                                                                    onFocus={() =>
                                                                        this.handleFoucus(
                                                                            STEPS.AUTORESPONSE
                                                                        )
                                                                    }
                                                                    onBlur={() =>
                                                                        this.handleFoucus("")
                                                                    }
                                                                >
                                                                    <MessageBox
                                                                        textAreaRows={5}
                                                                        handleChange={
                                                                            this
                                                                                .handleAutoReplyMessage
                                                                        }
                                                                        clearFileInput={
                                                                            this
                                                                                .clearAutoReplyFileInput
                                                                        }
                                                                        _handleImageChange={
                                                                            this
                                                                                .updateAutoReplyMediaObject
                                                                        }
                                                                        updateMediaObjectGiyf={
                                                                            this.updateAutoReplyGiyf
                                                                        }
                                                                        showAutoResponseToggle={
                                                                            false
                                                                        }
                                                                        addLinkToMessage={
                                                                            this
                                                                                .updateAutoReplyTextHandler
                                                                        }
                                                                        resetInput={
                                                                            resetAllComponentsData
                                                                        }
                                                                        addFirstNameText={
                                                                            this
                                                                                .updateAutoReplyTextHandler
                                                                        }
                                                                        addDefualtText={
                                                                            autoReplyMessageText
                                                                        }
                                                                        mediaUrl={
                                                                            editCampaignAutoResponseMediaUrl
                                                                        }
                                                                        clearMediaUrl={
                                                                            this
                                                                                .clearAutoResponseMediaUrl
                                                                        }
                                                                        updateTextState={
                                                                            this
                                                                                .updateAutoReplyTextHandler
                                                                        }
                                                                        isMediaUpdate={
                                                                            editCampaignIsMediaUpdateAutoResponse
                                                                        }
                                                                    />
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            ) : null}
                                            {user.Company.AgeGated === 1 ? (
                                                <>
                                                    <div className="row">
                                                        <div className="w-2-5">
                                                            <div
                                                                className={`new-campaign-panebar-age-gated `}
                                                                style={{
                                                                    borderColor: this.checkValidationForMessageBorder(
                                                                        isSubmitCallInitiated
                                                                    )
                                                                        ? "#dc3545"
                                                                        : "#3AAF50"
                                                                }}
                                                            >
                                                                <span></span>
                                                            </div>
                                                        </div>
                                                        <div className="w-9-5 newcampaign-modal-form-right">
                                                            <label style={{ color: "black" }}>
                                                                Does your campaign include any adult
                                                                related content? (Alcohol, tobacco,
                                                                gambling, firearms, etc).
                                                            </label>
                                                            <>
                                                                <label style={{ display: "flex" }}>
                                                                    <label>
                                                                        This applies to the message
                                                                        body, auto-responder, and
                                                                        any attachments
                                                                    </label>
                                                                    <img
                                                                        src={infoIcon}
                                                                        alt="info icon"
                                                                        style={{
                                                                            width: "1.5vh",
                                                                            height: "1.5vh",
                                                                            opacity: 0.5,
                                                                            marginLeft: "2vh",
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={() => {
                                                                            window.open(
                                                                                "https://help.digitalrange.com/en/articles/5753861-age-gated-content",
                                                                                "_blank"
                                                                            );
                                                                        }}
                                                                    />
                                                                    <label
                                                                        style={{
                                                                            marginLeft: "0.5vh",
                                                                            cursor: "pointer"
                                                                        }}
                                                                        onClick={() => {
                                                                            window.open(
                                                                                "https://help.digitalrange.com/en/articles/5753861-age-gated-content",
                                                                                "_blank"
                                                                            );
                                                                        }}
                                                                    >
                                                                        Age-Gate Policy
                                                                    </label>{" "}
                                                                </label>
                                                            </>
                                                            <div className="age-gated newcampaign-modal-form-vertical-spacing">
                                                                <ul className={""}>
                                                                    <li>
                                                                        {/* <input
                                                                                type="radio"
                                                                                id={`active-items1`}
                                                                                className="active-items"
                                                                                value="Yes"
                                                                                onChange={this.onValueChange.bind(this)}
                                                                                checked={this.state.radio === "Yes"}
                                                                            />   */}
                                                                        <RoundCheckBox
                                                                            id={CHECKBOX_SELECT.YES}
                                                                            checked={
                                                                                this.state.radio
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .onChangeRadioButton
                                                                            }
                                                                        />
                                                                        {/* <Checkbox color="success" name="tac" value="Yes" shape="round" animation="jelly" onChange={this.onValueChange.bind(this)} checked={this.state.radio === "Yes"} /> */}
                                                                        <label
                                                                            style={
                                                                                this.state.radio ===
                                                                                CHECKBOX_SELECT.YES
                                                                                    ? {
                                                                                          color:
                                                                                              "black"
                                                                                      }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {"Yes"}
                                                                        </label>
                                                                    </li>
                                                                    <li className="flex-5">
                                                                        <RoundCheckBox
                                                                            id={CHECKBOX_SELECT.NO}
                                                                            checked={
                                                                                this.state.radio
                                                                            }
                                                                            onChange={
                                                                                this
                                                                                    .onChangeRadioButton
                                                                            }
                                                                        />
                                                                        <label
                                                                            style={
                                                                                this.state.radio ===
                                                                                CHECKBOX_SELECT.NO
                                                                                    ? {
                                                                                          color:
                                                                                              "black"
                                                                                      }
                                                                                    : {}
                                                                            }
                                                                        >
                                                                            {
                                                                                "No, my campaign does not contain any adult content"
                                                                            }
                                                                        </label>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>
                                            ) : (
                                                <></>
                                            )}
                                        </form>

                                        <div className="row">
                                            <div className="w-2-5">
                                                <div
                                                    className={`new-campaign-panebar br-0 ${this.checkValidation(
                                                        STEPS.READY
                                                    )}`}
                                                >
                                                    <span
                                                        className={`${this.checkValidation(
                                                            STEPS.READY
                                                        )}`}
                                                    >
                                                        Ready to go out!
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="w-9-5 newcampaign-modal-form-right">
                                                <div className="mail-send-type">
                                                    <ul>
                                                        <li
                                                            style={{
                                                                color: activeSendTest
                                                                    ? "#1D3152"
                                                                    : "",
                                                                borderColor: activeSendTest
                                                                    ? "#1D3152"
                                                                    : ""
                                                            }}
                                                            className="senttest-btn"
                                                            onClick={() =>
                                                                this.makeLastStepActive(
                                                                    LAST_STEP_TYPE.TEST
                                                                )
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color: activeSendTest
                                                                        ? "#1D3152 "
                                                                        : ""
                                                                }}
                                                            >
                                                                Send test
                                                            </span>
                                                        </li>
                                                        <li
                                                            style={{
                                                                color: activeSendItNow
                                                                    ? "#3AAF50"
                                                                    : "",
                                                                borderColor: activeSendItNow
                                                                    ? "#3AAF50"
                                                                    : ""
                                                            }}
                                                            className="sentitnow-btn"
                                                            onClick={() =>
                                                                this.makeLastStepActive(
                                                                    LAST_STEP_TYPE.SEND
                                                                )
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color: activeSendItNow
                                                                        ? "#3AAF50 "
                                                                        : ""
                                                                }}
                                                            >
                                                                Send it Now
                                                            </span>
                                                        </li>
                                                        <li
                                                            style={{
                                                                color: activeSchdule
                                                                    ? "#1D3152"
                                                                    : "",
                                                                borderColor: activeSchdule
                                                                    ? "#1D3152"
                                                                    : ""
                                                            }}
                                                            className="scheduled-btn"
                                                            onClick={() =>
                                                                this.makeLastStepActive(
                                                                    LAST_STEP_TYPE.SCHDULE
                                                                )
                                                            }
                                                        >
                                                            <span
                                                                style={{
                                                                    color: activeSchdule
                                                                        ? "#1D3152 "
                                                                        : ""
                                                                }}
                                                            >
                                                                Schedule
                                                            </span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="w-2-5"></div>
                                            <div className="w-9-5 newcampaign-modal-form-right">
                                                {activeSendItNow ? (
                                                    <div className="row default-send-campaign-btn">
                                                        <div className="w-12">
                                                            <div className="text-right">
                                                                {isFetchingCount ? (
                                                                    <Spinner
                                                                        size="lg"
                                                                        color={`${this.state
                                                                            .confirmationModalValues
                                                                            .color || "green"}`}
                                                                    />
                                                                ) : (
                                                                    <button
                                                                        className={`chat-form-btn ${
                                                                            this.isStepOneCompleted() &&
                                                                            this.isStepTwoCompleted() &&
                                                                            this.isStepThreeCompleted()
                                                                                ? `valid`
                                                                                : ``
                                                                        }`}
                                                                        onClick={() =>
                                                                            this.handleSendButton(
                                                                                LAST_STEP_TYPE.SEND
                                                                            )
                                                                        }
                                                                    >
                                                                        Send
                                                                    </button>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}

                                                {activeSendTest ? (
                                                    <div className="row senttest-box">
                                                        <div className="w-12">
                                                            <div className="pressing-btn-msg">
                                                                <p>
                                                                    If your number isn't showing in
                                                                    the dropdown please make sure
                                                                    you add yourself as a contact
                                                                    and opt-in to receive messages
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div className="w-12 row">
                                                            <div className="w-6">
                                                                <TagInputComponent
                                                                    verticalSpacing={0}
                                                                    segments={this.props.segments}
                                                                    updateContact={
                                                                        this
                                                                            .updateTestCampaignSelectedContacts
                                                                    }
                                                                    selectedSegments={{}}
                                                                    selectedContacts={
                                                                        selectedTestCampaignContacts
                                                                    }
                                                                    resetTagInput={
                                                                        resetAllComponentsData
                                                                    }
                                                                    onFocus={() =>
                                                                        this.handleFoucus(
                                                                            STEPS.CONTACT
                                                                        )
                                                                    }
                                                                    onBlur={() =>
                                                                        this.handleFoucus("")
                                                                    }
                                                                    editCampaign={editCampaign}
                                                                    allContactSelected={
                                                                        editCampaign
                                                                    }
                                                                    focusedBox={
                                                                        this.state.focusedBox
                                                                    }
                                                                    selectionType={
                                                                        this.state
                                                                            .testCampainSelectionType
                                                                    }
                                                                    updateAllContactSelection={
                                                                        this
                                                                            .updateAllContactSelection
                                                                    }
                                                                    hideAllContacts={true}
                                                                    placeholder="Enter Contacts"
                                                                />
                                                            </div>
                                                            <div>
                                                                {this.state.testCampaignSent ? (
                                                                    <div
                                                                        style={{
                                                                            display: "flex",
                                                                            width: "110px",
                                                                            height: "100%",
                                                                            marginLeft: "10px",
                                                                            justifyContent:
                                                                                "space-around",
                                                                            alignItems: "center"
                                                                        }}
                                                                    >
                                                                        <img
                                                                            src={greenTick}
                                                                            alt={"Green tick"}
                                                                            style={{
                                                                                width: "30px",
                                                                                height: "30px"
                                                                            }}
                                                                        />
                                                                        <span
                                                                            style={{
                                                                                color: "#344C60",
                                                                                fontFamily:
                                                                                    "Montserrat, sans-serif",
                                                                                fontSize: "15px",
                                                                                fontWeight: "700"
                                                                            }}
                                                                        >
                                                                            Test Sent
                                                                        </span>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        {isFetchingCount ? (
                                                                            <Spinner
                                                                                size="lg"
                                                                                color={`${this.state
                                                                                    .confirmationModalValues
                                                                                    .color ||
                                                                                    "green"}`}
                                                                            />
                                                                        ) : (
                                                                            <button
                                                                                disabled={
                                                                                    !this.getActiveSelectedTestContacts()
                                                                                        .length
                                                                                }
                                                                                className="sendtest-btn"
                                                                                onClick={() =>
                                                                                    this.handleSendButton(
                                                                                        LAST_STEP_TYPE.TEST
                                                                                    )
                                                                                }
                                                                            >
                                                                                Send test
                                                                            </button>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                        {/* <div className="w-12">
                                                            <div className="senttest-input"> */}
                                                        {/* <input type="text" placeholder="Enter phone number" value={testPhoneInput} onChange={this.handlePhoneInputChange}/> */}
                                                        {/* <InputMask
                                                                    id="sendMessage"
                                                                    mask="+1 (999)-999-9999"
                                                                    className={`form-control contact-no`}
                                                                    placeholder="Phone Number"
                                                                    name="phoneNumber"
                                                                    onChange={this.handlePhoneInputChange}
                                                                    value={testPhoneInput}
                                                                    type="text"
                                                                    required
                                                                    disabled={false}
                                                                    autoComplete="off"
                                                                /> */}

                                                        {/* {testPhoneInput.length ? (
                                                                    <div className="sendtest-readmore" onClick={() => this.addTestPhoneListHandler()}>
                                                                        <img src={SegmentPlusIcon} class="img-fluid" alt="img" />
                                                                        <span>Add More</span>
                                                                    </div>
                                                                ) : null} */}

                                                        {/* <ul>
                                                                    {testPhoneDetails.length ?
                                                                        testPhoneDetails.map((phone, i) => {
                                                                            return (
                                                                                <li key={i}>
                                                                                    <div className="send-test-phone-number">
                                                                                        <input type="checkbox" id={`sendtest-checkbox-label-${i}`} class="sendtest-checkbox" onClick={() => this.changeTestPhoneDetailStatus(phone.phoneNumber)} checked={phone.active} />
                                                                                        <label htmlFor={`sendtest-checkbox-label-${i}`}></label>
                                                                                        <span>
                                                                                            {phone.phoneNumber}
                                                                                        </span>
                                                                                    </div>
                                                                                </li>
                                                                            )

                                                                        })
                                                                        : null}
                                                                </ul> */}
                                                        {/* </div>
                                                        </div> */}
                                                    </div>
                                                ) : null}

                                                {activeSchdule ? (
                                                    <div className="scheduledbox">
                                                        <div className="row">
                                                            <div className="w-12">
                                                                <div className="pressing-btn-msg">
                                                                    <p>
                                                                        Select Day and Time you
                                                                        would like to shedule the
                                                                        campaign.
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="w-4">
                                                                <DatePicker
                                                                    selected={scheduleDate}
                                                                    onChange={(date) =>
                                                                        this.resetSchduleDate(date)
                                                                    }
                                                                    timeInputLabel="Time:"
                                                                    minDate={new Date()}
                                                                    minTime={new Date()}
                                                                    dateFormat="MMMM d, yyyy h:mm aa"
                                                                    showTimeInput
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="w-12">
                                                                <div className="newcampaign-btn">
                                                                    {isFetchingCount ? (
                                                                        <Spinner
                                                                            size="lg"
                                                                            color={`${this.state
                                                                                .confirmationModalValues
                                                                                .color || "green"}`}
                                                                        />
                                                                    ) : (
                                                                        <button
                                                                            className={`contacts-btn ${
                                                                                this.checkValidation(
                                                                                    STEPS.READY
                                                                                ) ===
                                                                                STEPS_CLASS.GRAYOUT
                                                                                    ? "schedule-invalid"
                                                                                    : ""
                                                                            } `}
                                                                            onClick={() =>
                                                                                this.handleSendButton(
                                                                                    LAST_STEP_TYPE.SCHDULE
                                                                                )
                                                                            }
                                                                        >
                                                                            Schedule
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="w-4 scroll-styling"
                                    style={{ height: "calc(100vh - 5.0625rem)", overflow: "auto" }}
                                >
                                    <div className="setting-tab-right-section">
                                        <div className="mobile-phone">
                                            {!messageText && !mediaBase64Url && !requestReply && (
                                                <div
                                                    style={{
                                                        margin: "auto",
                                                        fontSize: "15px",
                                                        color: "#D2DADE"
                                                    }}
                                                >
                                                    {" "}
                                                    Preview Your Campain
                                                </div>
                                            )}
                                            {messageText && (
                                                <div
                                                    className={`text-msg ${
                                                        messageText && messageText.length > 250
                                                            ? `text-msg-scroll`
                                                            : ``
                                                    }`}
                                                >
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: achromeText
                                                        }}
                                                    ></p>
                                                </div>
                                            )}
                                            {mediaBase64Url && (
                                                <div className="img-fluid-container">
                                                    <img
                                                        className="img-fluid"
                                                        src={mediaBase64Url}
                                                        alt="smartphone"
                                                        style={{ width: "80%", maxHeight: "180px" }}
                                                    />
                                                </div>
                                            )}
                                            {this.state.editCampaignMediaUrl && (
                                                <div className="img-fluid-container">
                                                    <img
                                                        className="img-fluid"
                                                        src={this.state.editCampaignMediaUrl}
                                                        alt="smartphone"
                                                        style={{ width: "80%", maxHeight: "180px" }}
                                                    />
                                                </div>
                                            )}
                                            {requestReply ? (
                                                <div className="text-msg">
                                                    <p
                                                        dangerouslySetInnerHTML={{
                                                            __html: this.state.autoReplyText
                                                        }}
                                                    ></p>
                                                </div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.showCampaignSucessAlertModal && (
                    <CustomModal
                        isOpen={this.state.showCampaignSucessAlertModal}
                        handleModalState={() =>
                            this.props.history.push("/dashboard/inbox/all-messages/")
                        }
                    >
                        <ContactAlertModal
                            color={"green"} // todo
                            handleClose={() =>
                                this.props.history.push("/dashboard/inbox/all-messages/")
                            }
                            icon={SuccessIcon}
                            content={
                                this.state.activeSchdule ? `Campaign Scheduled` : `Campaign Sent`
                            }
                        />
                    </CustomModal>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        showTestMessageModal: state.campaigns.showTestMessageModal,
        showAutoReplyModal: state.campaigns.showAutoReplyModal,
        totalContacts: state.contacts.allContacts.totalContacts,
        totalCampaignContacts: state.campaigns.campaignReportDetails.totalCampaignContacts,
        segments: state.contacts.segments.data,
        segmentsFetching: state.contacts.segments.fetching,
        user: state.auth.user,
        allContacts: state.contacts.allContacts.list,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getContactsList: (paramsObj) => dispatch(getContactsList(paramsObj)),
        getContactsByCampaignId: (id, paramsObj) =>
            dispatch(getContactsByCampaignId(id, paramsObj)),
        imageUpload: (bodyData) => dispatch(imageUpload(bodyData)),
        createCampaign: (bodyData) => dispatch(createCampaign(bodyData)),
        campaignCount: (bodyData) => dispatch(campaignCount(bodyData)),

        getSegments: () => dispatch(getSegments()),
        handleAutoReplyModal: (show) => dispatch(handleAutoReplyModal(show)),
        handleTestMessageModal: (show) => dispatch(handleTestMessageModal(show)),
        getGiyf: (query, limit, offset) => dispatch(getGiyf(query, limit, offset)),
        sendTestMessage: (bodyData) => dispatch(sendTestMessage(bodyData)),
        getCampaignById: (id) => dispatch(getCampaignById(id)),
        editCampaign: (bodyData) => dispatch(editCampaign(bodyData))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TextSmsCampaigns);
