import queryString from 'query-string';
import { toast } from "react-toastify";

import { fetchUtil } from "../../utils/fetchUtils";
import { getToken } from "../../utils/tokenUtils";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";

const getSegments = async () => {
    const token = getToken();
    const { Segments } = await fetchUtil({
        url: `/company/segment`,
        token
    });
    return Segments;
};

export const useGetSegments = () => {
    const { data = [] } = useQuery({
        queryKey: ["segments"],
        queryFn: getSegments
    });

    return data;
};

const getContacts = async (params) => {
    const token = getToken();
    const data = await fetchUtil({
        url: `/contacts?${queryString.stringify(params, {arrayFormat: 'bracket'})}`,
        token
    });
    return data;
};

export const useGetContacts = (params, options = {}) => {
    const defaultParams = {
        OptStatus: [1, 4],
        Limit: 1000,
    };

    const { data = [], ...rest } = useQuery({
        queryKey: ["contacts", {...defaultParams, ...params}],
        queryFn: () => getContacts({...defaultParams, ...params}),
        ...options
    });

    return [data, rest];
};

const getContactsTotalCount = async () => {
    const token = getToken();
    return await fetchUtil({
        url: `/contacts/count?ByPhoneNumber=false`,
        token
    });
};

export const useGetContactsTotalCount = () => {
    const { data = [] } = useQuery({
        queryKey: ["contactsTotalCount"],
        queryFn: () => getContactsTotalCount()
    });

    return data;
};

const updateContact = async (data) => {
    const token = getToken();
    return await fetchUtil({
        url: `/contact`,
        method: "PUT",
        body: JSON.stringify(data),
        token
    });
}

export const useUpdateContact = (options = {}) => {
  const { mutate } = useMutation({
      ...options,
      mutationFn: updateContact,
  });

  return mutate;
}

const sendOnBoardForm = async (data = {}) => {
    const token = getToken();
    return await fetchUtil({
        url: "/contacts/request/onboard",
        method: "POST",
        token,
        body: JSON.stringify(data)
    });
}

export const useSendOnBoardForm = (options = {}) => {
  const { mutate } = useMutation({
      ...options,
      mutationFn: sendOnBoardForm,
  });

  return mutate;
}

const sendContactCard = async (data = {}) => {
    const token = getToken();
    return await fetchUtil({
        url: "/contacts/send/card",
        method: "POST",
        token,
        body: JSON.stringify(data)
    })
};

export const useSendContactCard = (options = {}) => {
    const { mutate } = useMutation({
        ...options,
        mutationFn: sendContactCard,
    });
  
    return mutate;
}

const bulkUpdateContactStatus = async (data) => {
    const token = getToken();
    return await fetchUtil({
        url: "/contacts/status",
        method: "PUT",
        body: JSON.stringify(data),
        token
    });
};

export const useBulkUpdateContactStatus = () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: bulkUpdateContactStatus,
        onSuccess() {toast("Updated!", { containerId: "success" })},
        onError() {toast("Something went wrong", { containerId: "error" })},
        onSettled() {
            queryClient.invalidateQueries(["contactsTotalCount"])
            queryClient.invalidateQueries(["contacts"])
        }
    });
  
    return mutate;
}

const deleteContact = async (id) => {
    const token = getToken();
    return fetchUtil({
        url: `/contact/${id}`,
        method: "DELETE",
        token
    });
};

export const useDeleteContact = () => {
    const queryClient = useQueryClient();
    const { mutate } = useMutation({
        mutationFn: deleteContact,
        onSuccess() {toast("Deleted!", { containerId: "success" })},
        onError() {toast("Something went wrong", { containerId: "error" })},
        onSettled() {
            queryClient.invalidateQueries(["contactsTotalCount"])
            queryClient.invalidateQueries(["contacts"])
        }
    });
  
    return mutate;
}