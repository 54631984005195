import React, { useMemo } from "react";

import isEmpty from "lodash/isEmpty";
import noop from "lodash/noop";
import find from "lodash/find";

import { Tree } from "antd";

import { useGetContactsTotalCount } from "../../../hooks/api/contacts";
import LabelWithCount from "../../atoms/Typography/Label/WithCount";

const DEFAULT_STYLE = {
  display: "flex",
  padding: "10px 0",
};

export const PRESET_SEGMENTS_KEYS = {
  ACTIVE: "ACTIVE",
  INVITED: "INVITED",
  UNSUBSCRIBED: "UNSUBSCRIBED",
};

const PRESET_SEGMENTS = [
  {
    title: ({total}) =>
      <LabelWithCount
        label="Active"
        count={total}
        className="w-56"
        labelClassName="sm:text-xl text-secondary"
        countClassName="sm:text-xl text-secondary"
      />,
    key: PRESET_SEGMENTS_KEYS.ACTIVE,
    total: null,
    style: {
      ...DEFAULT_STYLE,
      padding: "20px 0",
      borderBottom: "1px solid #e5e7eb",
    },
    children: [
      {
        title: ({total}) =>
          <LabelWithCount
            className="w-52"
            label="No Segment"
            count={total}
          />,
        key: -1,
        total: null,
        style: {
          ...DEFAULT_STYLE,
          paddingBottom: 80,
          borderBottom: "1px solid #e5e7eb",
        },
      }
    ],
  },
  {
    title: ({total}) =>
      <LabelWithCount
        className="w-56"
        label="Invited"
        count={total}
      />,
    key: PRESET_SEGMENTS_KEYS.INVITED,
    total: null,
    style: DEFAULT_STYLE,
  },
  {
    title: ({total}) =>
      <LabelWithCount
        className="w-56"
        label="Unsubscribed"
        count={total}
      />,
    key: PRESET_SEGMENTS_KEYS.UNSUBSCRIBED,
    total: null,
    style: DEFAULT_STYLE,
  }
];

const ContactSegments = ({
  onChange = noop,
  value = [PRESET_SEGMENTS_KEYS.ACTIVE]
}) => {
  const contactsTotalCount = useGetContactsTotalCount();

  const segments = useMemo(() => {
    const segments = [...PRESET_SEGMENTS];

    if(!isEmpty(contactsTotalCount)) {
      const normalizedSegmentsCount = contactsTotalCount
        .Segments
        .map(({
          TotalActiveContacts,
          segment
        }) => ({
          count: TotalActiveContacts,
          id: segment.Id,
        }));
      const activeSegments = find(segments, { key: PRESET_SEGMENTS_KEYS.ACTIVE });

      activeSegments.total = contactsTotalCount.TotalActiveContacts;
      find(segments, { key: PRESET_SEGMENTS_KEYS.INVITED }).total = contactsTotalCount.TotalContactsInInvite;
      find(segments, { key: PRESET_SEGMENTS_KEYS.UNSUBSCRIBED }).total = contactsTotalCount.TotalInactiveContacts;

      // ADD CHILD SEGMENTS
      activeSegments.children = contactsTotalCount.Segments
        .filter(({segment}) => !activeSegments.children.find(({key}) => key === segment.Id))
        .map(({segment}) => ({
          title: ({total}) =>
            <LabelWithCount
              className="w-52"
              label={segment.Title}
              count={total}
            />,
          key: segment.Id,
          total: find(normalizedSegmentsCount, { id: segment.Id }).count,
          style: DEFAULT_STYLE,
        }))
        // TO ENSURE THAT NO SEGMENT IS ALWAYS AT THE END
        .concat(activeSegments.children);

      // NO SEGMENT COUNT
      find(activeSegments.children, { key: -1 }).total = contactsTotalCount.TotalActiveWithNoSegment;
    }

    return segments;
  }, [contactsTotalCount]);

  const onCheck = (checkedKeys, ev) => {
    if(isEmpty(checkedKeys)) {
      onChange([PRESET_SEGMENTS_KEYS.ACTIVE]);
      return;
    }

    if(ev.checked && isNaN(ev.node.key)) {
      onChange([ev.node.key])
    } else {
      onChange(checkedKeys.filter(k => !isNaN(k)));
    }
  } 

  return (
    <div className="px-2 border h-full w-80 border-gray-200 overflow-y-auto shrink-0">
      <Tree
        checkable
        defaultExpandAll
        blockNode
        selectable={false}
        treeData={segments}
        checkedKeys={value}
        onCheck={onCheck}
      />
    </div>
  );
};

export default ContactSegments;
