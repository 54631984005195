import React from "react";
import { connect } from "react-redux";
import { DebounceInput } from "react-debounce-input";
import "../../../App.css";
import { SearchBar } from "../../../components/searchBar";
import {
    setChatDetailsFromAllContacts,
    getChatList,
    setCurrentChatDetails,
    getCurrentChat,
    changeCurrentChatContactStatus,
    updateChatListOnStatusChange,
    changeChatContactStatus,
    setUnreadChatAndList,
    setImageLoadingScroll,
    removeCurrentChat,
    getChatByContact,
    getUnreadChatCountAndList,
    updateChatReadStatus
} from "../../../redux/actions";
import { Spinner } from "../../../components/Loader";
import { InboxMessageListItem } from "../";
import { ChatMessageTypes, ChatReadStatus, ContactStatus } from "../../../config/constants";
import Socket from "../../../utils/socketUtils";
import { convertDateTime } from "../../../utils/dateTimeUtils";
import { AsideIcon } from "../../../assets/svg-icon";
class InboxAllMessages extends React.Component {
    constructor() {
        super();
        this.state = {
            selectedChatId: null,
            searchText: "",
            onSearchInlist: 0,
            page: 1
        };
        this.abortController = new AbortController();
        this.searchQuery = false;
    }
    async componentDidMount() {
        let id = this.props.match.params.id;
        let Chat;
        if (this.props.location.state && this.props.location.state.contactId) {
            Chat = await this.props.getChatByContact(this.props.location.state.contactId);
        }
        Socket.listenContactStatus(this.onContactStatusChange);
        this.props.getUnreadChatCountAndList();
        this.props
            .getChatList({ FilterType: this.props.filterType }, this.abortController.signal)
            .then(async (res) => {
                this.props.setImageLoadingScroll(true);
                if (res.length) {
                    if (!id) {
                        if (this.props.location.state && this.props.location.state.contactId) {
                            this.props.getCurrentChat(Chat.Id);
                            this.handleConversationClick(Chat);
                            this.setState({ selectedChatId: +Chat.Id });
                        }
                    } else if (id) {
                        let isExitIndex = res.findIndex((chat) => {
                            return chat.Id === Number(id);
                        });
                        if (isExitIndex > -1) {
                            this.handleToPullUnreadChat(res[isExitIndex]);
                        }
                    }
                }
                this.handleScrollPosition();
            })
            .catch((err) => {});
        if (id) {
            this.props.getCurrentChat(id);
            this.setState({ selectedChatId: +id });
        }
    }
    componentDidUpdate(prevProps) {
        if (prevProps.match.params.id !== this.props.match.params.id) {
            if (!this.props.match.params.id) {
                this.setState({ selectedChatId: null });
            }
        }
    }
    componentWillUnmount() {
        this.abortController.abort();
        Socket.remove("ContactOptStatus", this.onContactStatusChange);
    }
    onContactStatusChange = (data) => {
        let id = this.props.match.params.id;
        if (
            id &&
            this.props.currentChatData.Contact &&
            data.ContactId === this.props.currentChatData.Contact.Id
        ) {
            this.props.changeCurrentChatContactStatus(data.OptStatus);
            this.props.updateChatListOnStatusChange(data.ContactId);
        } else {
            this.props.changeChatContactStatus(data.ContactId, data.OptStatus);
        }
    };
    isChatOptedIn = (chat) => {
        if (chat.Contact) {
            return chat.Contact.OptStatus === ContactStatus.OPT_IN;
        } else {
            if (this.props.isOptedOut) {
                return false;
            } else {
                return true;
            }
        }
    };

    handleScrollPosition = () => {
        let parentElement = document.getElementById("chat-contact-scroll-div");
        let activeElement = parentElement.getElementsByClassName("active");
        if (!!activeElement.length) {
            parentElement.scrollTop =
                activeElement[0].offsetTop - activeElement[0].offsetHeight * 2;
        }
    };

    handleSearchChange = (e) => {
        let searchText = e.target.value;
        this.handleSearchSubmit(searchText);
        this.setState({ searchText });
    };

    handleSearchSubmit = (searchText) => {
        let page = 1;
        this.props.getChatList(
            {
                Page: page,
                FilterType: this.props.filterType,
                ...(searchText && { Q: searchText })
            },
            this.abortController.signal
        );
        this.setState({ page });
        this.searchQuery = !!searchText;
    };
    handleAddChats = () => {
        let { searchText, page } = this.state;
        const { dataAvailable, fetching } = this.props;

        if (this.props.chatList.length < 6 && dataAvailable && !fetching) {
            page = 1;
            this.props
                .getChatList(
                    {
                        Page: page,
                        FilterType: this.props.filterType,
                        ...(searchText && { Q: searchText })
                    },
                    this.abortController.signal
                )
                .then((res) => {});
            this.setState({ page });
        }
    };
    handleScroll = (e) => {
        const { dataAvailable, fetching } = this.props;
        const { searchText } = this.state;
        const bottom = e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 20;
        let page = this.state.page + 1;
        if (bottom && dataAvailable && !fetching) {
            this.props.getChatList(
                {
                    Page: page,
                    FilterType: this.props.filterType,
                    ...(searchText && { Q: searchText })
                },
                this.abortController.signal
            );
            this.setState({ page });
        }
    };
    getChatList = async (page) => {
        const { dataAvailable } = this.props;
        if (dataAvailable) {
            await this.props
                .getChatList(
                    {
                        Page: page,
                        FilterType: this.props.filterType
                    },
                    this.abortController.signal
                )
                .then((res) => {});
            this.setState({ page });
            return page + 1;
        } else {
            return false;
        }
    };
    handleChatType = (type) => {
        const { history } = this.props;

        switch (type) {
            case "open":
                this.props.removeCurrentChat();
                history.push("/dashboard/inbox/all-messages");
                break;
            case "close":
                this.props.removeCurrentChat();
                history.push("/dashboard/inbox/opt-out-messages");
                break;
            default:
                break;
        }
    };
    handleToPullUnreadChat = (chat) => {
        let isExitsIndex = this.props.unreadChatCountAndList.unreadChatId.findIndex(
            (unreadChat) => {
                return chat.Id === unreadChat.id;
            }
        );
        if (isExitsIndex > -1) {
            this.props.unreadChatCountAndList.unreadChatId.splice(isExitsIndex, 1);
            this.props.unreadChatCountAndList.Count = this.props.unreadChatCountAndList.unreadChatId.length;
            this.props.setUnreadChatAndList({
                unreadChatId: this.props.unreadChatCountAndList.unreadChatId,
                Count: this.props.unreadChatCountAndList.Count
            });
        }
    };
    handleConversationClick = (chat) => {
        this.props.setImageLoadingScroll(true);
        this.props.setCurrentChatDetails(chat);
        this.handleToPullUnreadChat(chat);
        this.setState({ selectedChatId: chat.Id }, () => {
            this.props.onConversationClick(chat.Id);
        });
    };
    getCreatedAt = (chat) => {
        return chat.LastChatEvent && chat.LastChatEvent.CreatedAt
            ? chat.LastChatEvent.CreatedAt
            : chat.CreatedAt;
    };
    render() {
        const { chatList, fetching, unreadChatCountAndList, dataAvailable } = this.props;
        let UnreadCount = 0;
        if (unreadChatCountAndList && unreadChatCountAndList.hasOwnProperty("Count")) {
            UnreadCount = unreadChatCountAndList.Count;
        }
        const { selectedChatId, searchText } = this.state;
        let loadingState = null;
        let noChatListView = null;
        if (!fetching && this.state.searchText.length === 0) {
            noChatListView = (
                <div className="tab-middle-content">
                    <img src={AsideIcon} className="img-fluid" alt="img" />
                    <h3>You're all caught up!</h3>
                    {UnreadCount > 0 ? (
                        <p>
                            We found <span> {UnreadCount} Open Chat</span>
                        </p>
                    ) : null}
                </div>
            );
        } else if (!fetching && this.state.searchText.length > 0) {
            noChatListView = (
                <div className="tab-middle-content">
                    <h3>No Chats Found</h3>
                    <p>Try modifying your search criteria</p>
                </div>
            );
        }
        if (this.state.onSearchInlist === 1) {
            loadingState = (
                <div className="inbox-chatbox-body-loader">
                    <Spinner size="xxl" color="green" />
                </div>
            );
        } else if (fetching) {
            loadingState = (
                <div className="table-scroll-loader ">
                    <Spinner size="xl" color="green" />
                </div>
            );
        }
        return (
            <div className="inbox-message-list-wrapper">
                <div className="chats-aside-content">
                    <div className="inbox-message-list-searchbar">
                        <div className="search-bar">
                            <DebounceInput
                                debounceTimeout={500}
                                element={SearchBar}
                                placeholder="Search"
                                value={searchText}
                                onChange={this.handleSearchChange}
                            />
                        </div>

                        <div className="tab-head">
                            <div className="row">
                                <div className="w-6">
                                    <h4
                                        onClick={() => {
                                            this.handleChatType("open");
                                        }}
                                        className={
                                            this.props.location.pathname.includes("all-messages")
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        Open
                                        {UnreadCount > 0 ? <span>({UnreadCount})</span> : null}
                                    </h4>
                                </div>
                                <div className="w-6">
                                    <h4
                                        onClick={() => {
                                            this.handleChatType("close");
                                        }}
                                        className={
                                            this.props.location.pathname.includes(
                                                "opt-out-messages"
                                            )
                                                ? "active"
                                                : ""
                                        }
                                    >
                                        Closed
                                    </h4>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        className="inbox-message-list-ul-wrapper smooth-scroll fancy-scroll"
                        id="chat-contact-scroll-div"
                    >
                        <div className="tab-content" onScroll={this.handleScroll}>
                            <div className="inbox-message-list">
                                {chatList.length > 0 && this.state.onSearchInlist !== 1
                                    ? chatList.map((chat, index) => {
                                          let time = convertDateTime(this.getCreatedAt(chat))
                                              .getTimeZoneMoment()
                                              .format("MM/DD/YYYY, hh:mm A")
                                              .replace(", ", " at ");
                                          return (
                                              <InboxMessageListItem
                                                  updateloadScroll={this.updateloadScroll}
                                                  key={index}
                                                  name={`${chat.Contact.FirstName} ${chat.Contact.LastName}`}
                                                  active={selectedChatId === chat.Id}
                                                  segments={chat.Contact.Segments}
                                                  unread={chat.ReadStatus === ChatReadStatus.UNREAD}
                                                  lastMessage={
                                                      chat.LastChatEvent
                                                          ? chat.LastChatEvent.SenderType ===
                                                            ChatMessageTypes.ONBOARDFORMNOTIFICATION
                                                              ? `Onboard form sent on ${time}`
                                                              : chat.LastChatEvent.SenderType ===
                                                                ChatMessageTypes.CONTACTCARDNOTIFICATION
                                                              ? `Contact card sent on ${time}`
                                                              : chat.LastChatEvent.Content
                                                          : "..."
                                                  }
                                                  showIndicator={
                                                      chat.LastChatEvent &&
                                                      (chat.LastChatEvent.SenderType ===
                                                          ChatMessageTypes.ONBOARDFORMNOTIFICATION ||
                                                          chat.LastChatEvent.SenderType ===
                                                              ChatMessageTypes.CONTACTCARDNOTIFICATION)
                                                  }
                                                  handleAddChats={this.handleAddChats}
                                                  contactId={chat.Contact.Id}
                                                  isChatOptedIn={!this.isChatOptedIn(chat)}
                                                  date={convertDateTime(this.getCreatedAt(chat))
                                                      .getTimeZoneMoment()
                                                      .calendar(null, {
                                                          sameDay: "h:mm A",
                                                          nextDay: "[Tomorrow]",
                                                          nextWeek: "dddd",
                                                          lastDay: "[Yesterday]",
                                                          lastWeek: "dddd",
                                                          sameElse: "MM/DD/YYYY"
                                                      })}
                                                  onClick={() => this.handleConversationClick(chat)}
                                              />
                                          );
                                      })
                                    : noChatListView}
                                {!dataAvailable ? (
                                    <p className="No-More-Feed" style={{ textAlign: "center" }}>
                                        No more feed to view
                                    </p>
                                ) : null}
                            </div>
                            {loadingState}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        chatList: state.chat.chatList.list,
        fetching: state.chat.chatList.fetching,
        dataAvailable: state.chat.chatList.dataAvailable,
        currentChatData: state.chat.currentChat.data,
        unreadChatCountAndList: state.chat.unreadChatCountAndList
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setUnreadChatAndList: (data) => dispatch(setUnreadChatAndList(data)),
        setChatDetailsFromAllContacts: (ContactId) =>
            dispatch(setChatDetailsFromAllContacts(ContactId)),
        getChatList: (paramsObj, signal) => dispatch(getChatList(paramsObj, signal)),
        setCurrentChatDetails: (data) => dispatch(setCurrentChatDetails(data)),
        getCurrentChat: (id) => dispatch(getCurrentChat(id)),
        changeCurrentChatContactStatus: (status) =>
            dispatch(changeCurrentChatContactStatus(status)),
        changeChatContactStatus: (contactId, status) =>
            dispatch(changeChatContactStatus(contactId, status)),
        setImageLoadingScroll: (status) => dispatch(setImageLoadingScroll(status)),
        updateChatReadStatus: (data) => dispatch(updateChatReadStatus(data)),
        removeCurrentChat: () => dispatch(removeCurrentChat()),
        getChatByContact: (contactId) => dispatch(getChatByContact(contactId)),
        getUnreadChatCountAndList: () => dispatch(getUnreadChatCountAndList()),
        updateChatListOnStatusChange: (chatId) => dispatch(updateChatListOnStatusChange(chatId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(InboxAllMessages);
