let Config = {
    PERSIST_SECRET_KEY: "RC_WEB_SECRET_KEY",
    ENVIRONMENT: "DEVELOPMENT",
    ENVIRONMENTS: {
        LOCAL: {
            API_URL: "https://api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
            SHORTEN_URL_DOMAIN: "https://clubch.at",
            WEBCHAT_URL_DOMAIN: "https://webchat.digitalrange.com/",
            WEBCHAT_APP_DOMAIN: "RC_WEB_WEBCHAT_APP_DOMAIN"
        },
        DEVELOPMENT: {
            API_URL: "https://api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
            SHORTEN_URL_DOMAIN: "https://clubch.at",
            WEBCHAT_URL_DOMAIN: "https://webchat.digitalrange.com/",
            WEBCHAT_APP_DOMAIN: "RC_WEB_WEBCHAT_APP_DOMAIN"
        },
        PRODUCTION: {
            API_URL: "https://api-connect.digitalrange.com",
            BASE_IMG_PATH: "https://s3.us-east-2.amazonaws.com/digital-range-connect",
            SHORTEN_URL_DOMAIN: "https://clubch.at",
            WEBCHAT_URL_DOMAIN: "https://webchat.digitalrange.com/",
            WEBCHAT_APP_DOMAIN: "RC_WEB_WEBCHAT_APP_DOMAIN",
            WIDGET_URL_DOMAIN: "https://widget.digitalrange.com/"
        }
    }
};

Config.env = () => {
    return Config.ENVIRONMENTS[Config.ENVIRONMENT];
};

export default Config;