import { errorToaster } from "../../utils/loggerUtils";
import { fetchUtil } from "../../utils/fetchUtils";
import { appendQueryParams } from "../../utils/urlUtils";
import { PAGINATION_LIMIT } from "../../config/constants";
import Config from "../../config";
import { handleFetchError } from "../../utils/errorHandlerUtils";
import { getToken } from "../../utils/tokenUtils";

export const CHANGE_CONTACT_MODAL_STATE = "CHANGE_CONTACT_MODAL_STATE";
export const CONTACT_LIST_REQUEST = "CONTACT_LIST_REQUEST";
export const CONTACT_LIST_SUCCESS = "CONTACT_LIST_SUCCESS";
export const CONTACT_LIST_FAIL = "CONTACT_LIST_FAIL";
export const ADD_CONTACT_SUCCESS = "ADD_CONTACT_SUCCESS";
export const UPDATE_CONTACT = "UPDATE_CONTACT";
export const DELETE_CONTACT = "DELETE_CONTACT";
export const SEGMENTS_REQUEST = "SEGMENTS_REQUEST";
export const SEGMENTS_SUCCESS = "SEGMENTS_SUCCESS";
export const SEGMENTS_FAIL = "SEGMENTS_FAIL";
export const CONTACT_ADDED = "CONTACT_ADDED";
export const SET_UPLOAD_LIST = 'SET_UPLOAD_LIST';
export const handleAddContactModal = (status) => {
    return {
        type: CHANGE_CONTACT_MODAL_STATE,
        payload: status
    };
};

export const addContact = (firstName, lastName, phone, segmentIds, email = "") => (
    dispatch,
    getState
) => {
    const token = getToken();
    let body = {
        FirstName: firstName,
        LastName: lastName,
        PhoneNumber: phone,
        SegmentIds: segmentIds
    };
    if (email && email.length) {
        body.Email = email;
    }
    body = JSON.stringify(body);
    return fetchUtil({
        url: "/contact",
        token,
        method: "POST",
        body
    })
        .then((response) => {
            const { Id } = response;
            return dispatch(getContactById(Id))
                .then((res) => {
                    dispatch({
                        type: ADD_CONTACT_SUCCESS,
                        payload: res
                    });
                    return Promise.resolve(res);
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getContactById = (id) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/contact/${id}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getChatByContact = (contactId) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/chat/contact/${contactId}`,
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getContactsList = (paramsObj = {}, signal = null) => (dispatch, getState) => {
    dispatch({
        type: CONTACT_LIST_REQUEST,
        Page: paramsObj.Page
    });
    const token = getToken();
    return fetchUtil({
        url: appendQueryParams("/contacts", {
            Limit: PAGINATION_LIMIT,
            ...paramsObj
        }),
        token,
        ...(signal && {
            signal
        })
    })
        .then((res) => {
            dispatch({
                type: CONTACT_LIST_SUCCESS,
                payload: res,
                Page: paramsObj.Page
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return Promise.reject(false);
            }
            dispatch({
                type: CONTACT_LIST_FAIL
            });
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const getFilteredContactsList = (paramsObj = {}, signal = null) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: appendQueryParams("/contacts/filters", {
            ...paramsObj
        }),
        token,
        ...(signal && {
            signal
        })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            if (err.name === "AbortError") {
                return Promise.reject(false);
            }
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const updateContact = (bodyData) => (dispatch, getState) => {
    const { data } = getState().contacts.segments;
    const { SegmentIds, ...rest } = bodyData;

    let Segments = data.filter((val) => SegmentIds.includes(val.Id));

    const token = getToken();
    return fetchUtil({
        url: "/contact",
        token,
        method: "PUT",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            dispatch({
                type: UPDATE_CONTACT,
                payload: {
                    ...rest,
                    Segments
                }
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const deleteContact = (id) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: `/contact/${id}`,
        method: "DELETE",
        token
    })
        .then((res) => {
            dispatch({
                type: DELETE_CONTACT,
                payload: id
            });
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};
export const deleteMultipleContact = (bodyData) => (dispatch, getState) => {
    const token = getToken();

    return fetchUtil({
        url: `/contacts/many`,
        method: "DELETE",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const uploadCsvContacts = (data, signal = null, randomId) => (dispatch, getState) => {
    console.log("randomId", randomId);
    const token = getToken();
    let body = new FormData();
    body.append("file", data);
    body.append("uploadId", randomId);
    const url = `${Config.env().API_URL}/contacts`;

    return fetch(url, {
        method: "POST",
        body,
        headers: {
            Authorization: token
        },
        credentials: "include",
        ...(signal && {
            signal
        })
    })
        .then(handleFetchError)
        .then((res) => Promise.resolve(res))
        .catch((err) => {
            if (err.name === "AbortError") {
                return Promise.reject(false);
            }
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const uploadDeleteCsvContacts = (data, signal = null) => (dispatch, getState) => {
    const token = getToken();
    let body = new FormData();
    body.append("file", data);
    const url = `${Config.env().API_URL}/contacts/delete`;

    return fetch(url, {
        method: "POST",
        body,
        headers: {
            Authorization: token
        },
        credentials: "include",
        ...(signal && {
            signal
        })
    })
        .then(handleFetchError)
        .then((res) => Promise.resolve(res))
        .catch((err) => {
            if (err.name === "AbortError") {
                return Promise.reject(false);
            }
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const downloadCsvTemplate = () => (dispatch, getState) => {
    const token = getToken();

    const url = `${Config.env().API_URL}/template`;
    return fetch(url, {
        headers: {
            Authorization: token
        },
        credentials: "include"
    })
        .then((res) => res.text())
        .then((res) => Promise.resolve(res))
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const getSegments = () => (dispatch, getState) => {
    const token = getToken();
    dispatch({
        type: SEGMENTS_REQUEST
    });

    return fetchUtil({
        url: "/company/segment",
        token
    })
        .then((res) => {
            dispatch({
                type: SEGMENTS_SUCCESS,
                payload: res.Segments
            });
            return Promise.resolve(res.Segments);
        })
        .catch((err) => {
            dispatch({
                type: SEGMENTS_FAIL
            });
            return Promise.reject(err);
        });
};
export const contactCount = () => (dispatch, getState) => {
    const token = getToken();

    return fetchUtil({
        url: "/contacts/count?ByPhoneNumber=false",
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getPublicSegments = (code) => (dispatch, getState) => {
    return fetchUtil({
        url: appendQueryParams("/contact/register/segments", {
            Code: code
        })
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const getSelectedSegemntCount = (segmentIds) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/contact/segment/count",
        method: "POST",
        body: JSON.stringify({ SegmentIds: segmentIds }),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const registerContact = (bodyData) => (dispatch, getState) => {
    return fetchUtil({
        url: "/contact/register",
        method: "POST",
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const changeContactStatus = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/contacts/status",
        method: "PUT",
        body: JSON.stringify(bodyData),
        token
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            errorToaster(err.Message);
            return Promise.reject(err);
        });
};

export const sendOnBoardForm = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/contacts/request/onboard",
        method: "POST",
        token,
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};

export const sendContactCard = (bodyData) => (dispatch, getState) => {
    const token = getToken();
    return fetchUtil({
        url: "/contacts/send/card",
        method: "POST",
        token,
        body: JSON.stringify(bodyData)
    })
        .then((res) => {
            return Promise.resolve(res);
        })
        .catch((err) => {
            return Promise.reject(err);
        });
};
export const setContactAddStatus = (data) => {
    return {
        type: CONTACT_ADDED,
        payload: data
    };
};
export const setUploadList = (data) => {
    return {
        type: SET_UPLOAD_LIST,
        payload: data
    };
};